<!-- Embedding projector section -->
<section>
  <div class="row mb-3">
    <h3>Visualisierung der Word Embeddings</h3>
    <p>
      Da mehr als drei Dimensionen nicht sinnvoll visualisiert werden können,
      wird die Dimension der Word Embeddings zunächst mit einem Verfahren namens
      Hauptkomponentenanalyse auf zwei beziehungsweise drei Dimensionen reduziert. Dabei
      geht ein Teil der in den Vektoren abgebildeten Information verloren. Die
      Visualisierung der verbleibenden Informationen kann aber eine erste Idee
      über die Qualität der erzeugten Word Embeddings geben.
    </p>
  </div>

  <div class="row justify-content-center mb-3">
    <div class="col">
      <ngb-alert
        *ngIf="!hideWarn"
        [dismissible]="true"
        (closed)="hideWarn = true"
      >
        <strong>Hinweis!</strong> Die trainierten Word Embeddings werden nur
        beim ersten Training automatisch in der Visualisierung dargestellt. Um
        bei einem weiteren Training die neu erzeugten Word Embeddings zu
        visualisieren, musst du den Knopf "Visualisierung laden" drücken.
      </ngb-alert>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md8">
      <select
        type="boolean"
        class="form-select inline-select"
        [(ngModel)]="use2DEmbeddings"
      >
        <option [ngValue]="false">3-dimensionale Word Embeddings</option>
        <option [ngValue]="true">2-dimensionale Word Embeddings</option>
      </select>
      <button
        type="button"
        class="btn btn-primary m-1"
        [ngClass]="{ 'btn-success': similaritiesCalculated }"
        (click)="loadEmbeddingVisualization()"
      >
        Visualisierung laden
        <span
          *ngIf="currentlyLoading"
          class="spinner-border spinner-border-sm"
        ></span>
      </button>
    </div>
  </div>

  <div class="row mb-3">
    <div class="embedding-projector-container">
      <div id="canvasSizeIndicator"></div>
      <canvas #embeddingProjector class="embedding-projector"></canvas>
    </div>
  </div>
</section>

<!-- Search similar words section -->
<section *ngIf="showSimilarWordsSection">
  <div class="row mb-3">
    <h3>Ähnliche Wörter suchen</h3>
    <p>
      Unter anderem ist es möglich, die ähnlichsten Wörter zu einem gegebenen
      Wort anhand der Word Embeddings des Wortes zu ermitteln. Dazu werden die
      beiden Word Embedding-Vektoren in einer Ähnlichkeitsfunktion verarbeitet
      und ein Ähnlichkeitsgrad berechnet.
    </p>
  </div>

  <div class="row mb-3 justify-content-center">
    <div class="col-md12 text-center mb-3">
      <span>Wähle ein Wort aus dem Vokabular: </span>
      <select
        class="form-select form-select-sm mx-2 inline-select"
        type="number"
        [(ngModel)]="selectedWord"
      >
        <option disabled>Wörter</option>
        <option *ngFor="let x of words" [ngValue]="x">
          {{ x }}
        </option>
      </select>
      <button
        type="button"
        class="btn btn-primary"
        [ngClass]="{ 'btn-success': similaritiesCalculated }"
        (click)="calculateSimilarities()"
      >
        Ähnlichste Wörter berechnen
        <span
          *ngIf="currentlyLoading"
          class="spinner-border spinner-border-sm"
        ></span>
      </button>
    </div>
    <div class="col-auto" *ngIf="mostSimilarWords !== []">
      <span>Die 10 ähnlichsten Wörter:</span>
      <ol>
        <li *ngFor="let x of mostSimilarWords">{{ x }}</li>
      </ol>
    </div>
  </div>
</section>

<!-- Word analogy arithmetic section -->
<section *ngIf="showCalculateWordsSection">
  <div class="row mb-3">
    <h3>Mit Word Embeddings rechnen</h3>
    <p>
      Außerdem lassen sich die abgebildeten Beziehungen zwischen Wörtern mit
      Hilfe arithmetischer Operationen auf den erzeugten Word Embeddings
      überprüfen. Existieren im Vokabular beispielsweise die Worte "König",
      "Königin", "Mann" und "Frau", so kann bei gut trainierten Word Embeddings
      beobachtet werden, dass das Ergebnis des Terms "König" - "Mann" + "Frau"
      berechnet auf den Word Embeddings der Wörter ungefähr dem Word Embedding
      des Wortes "Königin" entspricht.
    </p>
  </div>

  <div class="row mb-3 justify-content-center">
    <div class="col-md12 text-center mb-3">
      <span>Wähle Wörter aus dem Vokabular: </span>
      <select
        class="form-select form-select-sm mx-2 inline-select"
        type="number"
        [(ngModel)]="wordArithmeticBase"
      >
        <option disabled>Wörter</option>
        <option *ngFor="let x of words" [ngValue]="x">
          {{ x }}
        </option>
      </select>
      <span>-</span>
      <select
        class="form-select form-select-sm mx-2 inline-select"
        type="number"
        [(ngModel)]="wordArithmeticSub"
      >
        <option disabled>Wörter</option>
        <option *ngFor="let x of words" [ngValue]="x">
          {{ x }}
        </option>
      </select>
      +
      <select
        class="form-select form-select-sm mx-2 inline-select"
        type="number"
        [(ngModel)]="wordArithmeticAdd"
      >
        <option disabled>Wörter</option>
        <option *ngFor="let x of words" [ngValue]="x">
          {{ x }}
        </option>
      </select>
      <span>=</span>
      <span>{{ wordArithmeticResult }}</span>
      <button
        type="button"
        class="btn btn-primary"
        [ngClass]="{ 'btn-success': wordsCalculated }"
        (click)="calculateWords()"
      >
        Ähnlichste Wörter berechnen
        <span
          *ngIf="currentlyLoading"
          class="spinner-border spinner-border-sm"
        ></span>
      </button>
    </div>
    <div class="col-auto" *ngIf="mostSimilarCalculatedWords !== []">
      <span>Die 10 ähnlichsten Wörter:</span>
      <ol>
        <li *ngFor="let x of mostSimilarCalculatedWords">{{ x }}</li>
      </ol>
    </div>
  </div>
</section>

<!-- Closing section -->
<section>
  <div class="row mb-3">
    <p>
      Word2Vec ist ein relativ einfaches Verfahren zur Erzeugung von Word
      Embeddings. Da selbst dieses einfache Verfahren bei großen Textmengen sehr
      rechenintensiv ist, werden Word Embeddings in der Regel nicht selbst
      trainiert, sondern es werden vortrainierte Word Embeddings verwendet.
      Modernere Architekturen wie Transformer-Modelle, die beispielsweise auch für große
      Sprachmodelle eingesetzt werden, sind ebenfalls in der Lage, Embeddings
      während des Trainings zu erzeugen. Die Hauptunterschiede sind hier, dass
      die trainierten Transformer-Modelle im Gegensatz zu den Word2Vec-Modellen
      reale Anwendungen haben und, dass die Embeddings nicht für ganze Wörter,
      sondern meist nur für Wortbestandteile trainiert werden.
    </p>
  </div>
</section>

<!-- Second group of tasks -->
<div class="row justify-content-center mt-4 mb-4">
  <div class="col-md-8">
    <ngb-accordion [closeOthers]="false" #acc="ngbAccordion">
      <ngb-panel>
        <ng-template ngbPanelTitle>
          <span>
            <h4>Aufgabe 3</h4>
          </span>
        </ng-template>
        <ng-template ngbPanelContent>
          Untersuche deine selbsttrainierten Word Embeddings auf Wörter, deren
          Ähnlichkeiten gut oder schlecht dargestellt wurden und bewerte die
          Qualität deiner selbsttrainierten Word Embeddings.
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <ngb-accordion [closeOthers]="false" #acc="ngbAccordion">
      <ngb-panel>
        <ng-template ngbPanelTitle>
          <span>
            <h4>Aufgabe 4</h4>
          </span>
        </ng-template>
        <ng-template ngbPanelContent
          >Teste weitere Konfigurationen und vergleiche den Trainingsverlauf und
          die Qualtität der erzeugten Word Embeddings aus den Trainingsdurchläufen mit den verschiedenen Konfigurationen.
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>
