<main class="main-body">
  <!-- Navbar always visible, except in case of a survey url -->
  <app-navbar *ngIf="!inPath('mnista', 'mnistb')"></app-navbar>
  <div class="pb-3" [ngClass]="{ 'pt-4': inPath('mnista', 'mnistb') }">
    <router-outlet></router-outlet>
  </div>
</main>
<!-- constant footer -->
<footer class="main-footer">
  <div class="footer-top">
    <div class="wrapper">
      <div class="row">
        <div class="col-sm-6">
          <div>
            <img
              src="assets/branding/Logo_IPN.webp"
              class="figure-img img-fluid rounded mb-1"
              style="
                height: 60px;
                margin-top: 0;
                margin-bottom: 1rem !important;
              "
            /><br />
            <address>
              <h4>
                IPN - Leibniz-Institut für die Pädagogik der Naturwissenschaften
                und Mathematik
              </h4>
              <p>
                Olshausenstraße 62<br />24118 Kiel<br />Telefon
                <a href="tel:+494318805084">0431 - 880 5084</a><br />E-Mail:
                <a href="mailto:info@leibniz-ipn.de">info@leibniz-ipn.de</a>
              </p>
            </address>
          </div>
        </div>
        <div class="col-sm-6">
          <p>
            Erstellt von
            <a href="https://github.com/lukasboettcher">Lukas Böttcher</a
            >,<br />weiterentwickelt von der<br /><a
              href="https://www.ddi.inf.uni-kiel.de/de"
              >AG für Didaktik der Informatik CAU</a
            >.
          </p>
          <div class="partner">
            <img
              src="assets/branding/tf-norm-de-blackgreyfaku-rgb-0720.png.webp"
              class="figure-img img-fluid rounded mb-1"
              style="height: 40px; margin-top: -5px"
            /><br />
            <img
              src="assets/branding/telekom_logo.png"
              class="figure-img img-fluid rounded mb-1"
              style="height: 40px; margin-top: -5px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="container">
      <div class="liswrapper">
        <div role="listitem" class="listitem">
          <a class="item" href="https://www.leibniz-ipn.de/de/das-ipn/impressum"
            >Impressum</a
          >
        </div>
        <div role="listitem" class="listitem">
          <a
            class="item"
            href="https://www.leibniz-ipn.de/de/das-ipn/datenschutzerklaerung"
            >Datenschutz</a
          >
        </div>
        <div role="listitem" class="listitem">
          <a
            class="item"
            href="https://www.leibniz-ipn.de/de/das-ipn/barrierefreiheit"
            >Barrierefreiheit</a
          >
        </div>
      </div>
      <hr class="" />
      <div role="list" class="last-line">
        <div role="listitem" class="item">
          <p class="footer-end">
            © <span>{{ year }}</span> IPN - Leibniz-Institut für die Pädagogik
            der Naturwissenschaften und Mathematik
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
