import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-word-embeddings',
  templateUrl: './word-embeddings.component.html',
  styleUrls: ['./word-embeddings.component.css'],
})
export class WordEmbeddingsComponent {
  // Variable to keep track of the current stage of the learning environment
  stages: boolean[] = new Array(2).fill(false);

  // Variables to store the results of the training of the Word2Vec models
  embeddings: number[];
  words: any;

  loadEmbeddings(data: object) {
    this.embeddings = data["embeddings"];
    this.words = data["words"];
    this.stages[1] = true;
  }
  
  constructor() {}
}
