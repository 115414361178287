<div class="row mb-3">
    <div class="col">
        <h4>{{notebookTitle}}</h4>
        <p>
            <ng-content></ng-content>
        </p>
    </div>
    <div class="col-md-2 align-self-center">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <a [href]="notebookLink" target="_blank"
                class="btn btn-primary btn-lg">
                <svg xmlns="http://www.w3.org/2000/svg" width="60" fill="currentColor" class="bi bi-play-fill"
                    viewBox="0 0 16 16">
                    <path
                        d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                </svg>
            </a>
        </div>
    </div>
</div>