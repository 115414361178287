<div class="btn btn-primary btn-lg" [style]="{
    position: 'fixed',
    right: '10%',
    bottom: '5%'
}" (click)="onHelpClick(content)">?
    <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Anleitung</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ng-content></ng-content>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Ok click')">Ok</button>
        </div>
    </ng-template>
</div>