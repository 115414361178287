<div class="container pt-3">
  <div class="row mb-3">
    <div class="col-md-7">
      <h3>MNIST GAN Training</h3>
      <p>
        Neben den breits vorgestellten Arten des maschinellen Lernens, nämlich dem überwachten und verstärkenden
        Lernen, gibt es auch noch eine dritte Art.
        Diese nennt sich unüberwachtes Lernen, also der Gegensatz zum überwachten maschinellen Lernen.
        Der Unterschied liegt darin, wie die Maschine trainiert wird. Daraus ergeben sich dann auch neue
        Anwendungsmöglichkeiten, für welche die künstliche Intelligenz verwendet werden kann.
      </p>
      <p>
        Konkret werden bei dem unüberwachten maschinellen Lernen zum Training keine kategorisierten Daten benutzt,
        sondern es wird dem Algorithmus einfach ein großer Haufen an Daten gefüttert, mit dem Ziel, dass dieser
        Muster erkennt.
      </p>
    </div>
    <div class="col align-self-center">
      <figure class="figure">
        <img src="assets/gan-images/unsup-clustering.png.webp" class="figure-img img-fluid rounded mb-1">
        <figcaption class="figure-caption">Unüberwachtes Lernen</figcaption>
      </figure>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col">
      <p>
        Da das unüberwachte maschinelle Lernen ein riesiger Oberbegriff für sehr viele Methoden ist, werden wir uns hier
        praktisch nur mit einer speziellen Art von Algorithmen auseinandersetzen. Diese nennen sich GANs, kurz für
        Generative Adversarial Networks.
        GANs sind neuronale Netze die eigentlich aus zwei Netzen bestehen, einem Generator und einem Diskriminator.
        Der Generator erzeugt Daten und der Diskriminator bewertet, wie gut die erzeugten Daten vom Generator 
        verglichen mit echten Daten sind.
        Hierbei treten die beiden Netze in einer Art Wettkampf gegeneinander an. Da der Generator versucht, den
        Diskriminator zu betrügen, muss er dabei das Muster der Eingabedaten erlernen, die er zu imitieren versucht.
      </p>
      <p>
        Dies können wir sehr leicht anhand eines Beispiels mit dem MNIST Datensatz ausprobieren. Das bedeutet wir
        füttern den GAN Algorithmus mit sehr vielen Bildern von Zahlen und durch den Wettkampf sollte der Generator
        lernen, wie Zahlen aussehen, da er versucht den Diskriminator mit Fälschungen der echten Zahlen zu überlisten.
      </p>
    </div>
  </div>

  <app-gan-mnist-training></app-gan-mnist-training>

  <hr class="my-5">

  <div class="row my-3">
    <div class="col-lg-8">
      <h3>Vortrainiertes MNIST GAN Modell</h3>
      <p>
        Das Training eines GAN Netzes ist sehr aufwendig. Deswegen ist es schwer ein solches Netz von Grund auf zu
        trainieren.
        Verglichen mit der <a routerLink="/mnist">MNIST Zahlenerkennung</a> bedarf es deutlich mehr Rechenaufwand für
        das Training. Intuitiv lässt
        sich das dadurch erklären, dass der Algorithmus nicht nur entscheiden muss, um welche Zahl es sich handelt,
        sonder, dass er auch lernen muss, wie er eine Zahl mit dem Generator erstellen kann.
      </p>
      <p>
        Deswegen werden oft Hochleistungscomputer mit vielen Prozessoren und Grafikkarten verwendet, um große und
        umfangreiche Modelle zu erstellen, die es schaffen, die Eingabedaten au­then­tisch zu immitieren.
        Glücklicherweise können wir solche Modelle einfach laden und ausprobieren, ohne sie zuerst zu trainieren.
        Beispielsweise für MNIST existiert ein Modell, welches Zahlen erzeugen kann, die vom Original nicht zu
        unterscheiden sind.
      </p>
    </div>
    <div class="col-lg-4 text-center">
      <button class="btn btn-primary" [disabled]="!modelsLoaded" (click)="sampleLoop(fake, real)">
        {{loopId ? 'Generieren abbrechen':'MNIST Zahlen erstellen'}}</button>
      <div class="mt-1">
        <div>
          <div>
            <span>Fake MNIST</span>
          </div>
          <div>
            <canvas class="border" #fake width="280" height="28"></canvas>
          </div>
        </div>
        <div>
          <div>
            <span>Echte MNIST</span>
          </div>
          <div>
            <canvas class="border" #real width="280" height="140"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-lg-7">
      <h3>Gesichter Generieren</h3>
      <p>
        Schwarz-weiße Zahlen sind natürlich nur mäßig interessant. Das haben sich einige Forscher von der Universität
        Montreal auch gedacht und haben ein neuronales GAN entworfen, welches besonders gut für Bilder geeignet
        ist.
        Damit war es basierend auf dem CelebAHQ Datensatz möglich ein Modell zu trainieren, welches menschliche
        Gesichter erstellen kann.
        Bei dem CelebAHQ Datensatz handelt es sich um eine Sammlung von 30.000 Porträts von Schauspielern. Mit Hilfe der Bilder aus dieser 
        Datenbank ist es möglich, Gesichter zu erzeugen, die verblüffend echt aussehen und die
        so noch nicht existieren.
      </p>
      <p>
        Tatsächlich ist es dem GAN hierbei ziemlich egal, woraus die Eingabedaten bestehen. Je nach Datensatz kann das
        Netzwerk Gesichter, Autos, Landschaften und viel weiteres lernen.
      </p>
      <p>
        Um nun Gesichter zu erstellen, kannst du eines der Modelle auswählen und dann entweder eine Animation starten
        oder einzelne Bilder generieren.
        Es stehen drei Modelle zur Auswahl. DCGAN ist ein kleines Modell, welches schnell Ausgaben erzeugt, während die
        beiden Resnet Modelle komplexer sind und dementsprechend auch detailliertere Ausgaben erzeugen können.
      </p>
    </div>
    <div class="col-lg-5">
      <div class="d-flex flex-column justify-content-center align-items-center">

        <form>
          <div class="mb-3">
            <div class="form-floating">
              <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                [disabled]="!faceModelReady && currFaceModel !== undefined" id="inputGroupSelect01"
                [(ngModel)]="currFaceModelUrl" [ngModelOptions]="{standalone: true}" (change)="onFaceModelChange()">
                <option selected disabled value="">Modell auswählen</option>
                <option *ngFor="let x of listFaceModels" [value]="x.path">{{x.desc}}
                </option>
              </select>
              <label for="floatingSelect">Gesichtsmodell <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="faceModelLoading"></span></label>
            </div>
          </div>
          <div class="mb-3">
            <label for="customRange1" class="form-label">Eingabewert: {{latentRange}}</label>
            <input #latentSlider type="range" class="form-range" id="customRange1" [disabled]="!faceModelReady"
              [(ngModel)]="latentRange" (input)="onFaceLatentChange(latentSlider.value, faceCanvas)" type="range"
              id="range" name="volume" min="0" max="400" value="0">
          </div>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button class="btn btn-primary" [disabled]="!faceModelReady" (click)="animate(faceCanvas)">
              {{stop ? 'Gesichter animieren' : 'Animation abbrechen'}}
            </button>
            <button class="btn btn-primary" [disabled]="!faceModelReady" (click)="generate(faceCanvas)">
              Gesicht generieren
            </button>
          </div>
        </form>

        <div class="mt-3">
          <canvas class="face-canvas border" #faceCanvas id="canvas" width="256" height="256">
            Your browser does not support the canvas element.
          </canvas>
        </div>
      </div>
    </div>
  </div>
</div>