<div class="container pt-3">
  <div class="row">
    <div class="col-md-7">
      <h3>Objekterkennung</h3>
      <p>
        Was haben selbstfahrende Autos, Snapchat-Hundeohren-Filter und smarte
        Überwachungskameras gemeinsam? Sie alle benutzen Methoden der
        Künstlichen Intelligenz zur Objekterkennung in Bildern: Das
        selbstfahrende Auto muss Ampeln, Schilder, Autos und Fußgänger erkennen,
        der Snapchat-Filter muss einigermaßen genau auf dem Gesicht sitzen und
        die Überwachungskamera soll nur dann aufzeichnen, wenn Menschen im Bild
        zu sehen sind.
      </p>
      <p>
        In dieser Komponente kannst du mit dem neuronalen Netz CocoSSD
        experimentieren, das darauf trainiert ist, Objekte in Bildern zu
        erkennen. Dazu brauchst du nichts weiter als eine Webcam. Sobald du
        diese gestartet hast, beginnt die Objekterkennung. Du kannst jetzt
        beliebige Gegenstände in die Kamera halten und schauen, ob das Netz sie
        erkennt.
      </p>
      <p>
        CocoSSD ist ein vortrainiertes neuronales Netz, aber neuronale Netze
        wachsen ja nicht auf Bäumen. Wie du ein neuronales Netz zur
        Bilderkennung selbst trainieren kannst, erfährst du in der Komponente
        <a routerLink="/classify">Klassifikation</a>.
      </p>
    </div>
    <div class="col-md-5 align-self-center">
      <div class="d-flex flex-column align-items-center">
        <figure class="figure">
          <img
            src="assets/obj-detect-images/faceapp.jpg.webp"
            class="figure-img img-fluid rounded"
          />
          <figcaption class="figure-caption">
            FaceApp kann ernste in lachende Gesichter verwandeln – mit Methoden
            der KI
          </figcaption>
        </figure>
      </div>
    </div>
  </div>

  <div class="row my-3">
    <div class="col-md-6">
      <div class="card my-2">
        <img
          class="card-img-top"
          src="assets/obj-detect-images/coco-semantics.png.webp"
          alt="Card image cap"
        />
        <div class="card-body">
          <p class="card-text">
            CocoSSD ist ein Modell, welches mittels der „Common Objects in
            Context“-Datenbank trainiert wurde. Diese Datenbank beinhaltet ca.
            330.000 Bilder, die Objekte aus über 170 Kategorien zeigen, welche
            durch das Modell zugeordnet werden können. Die Bilder sind so
            aufbereitet worden, dass das neuronale Netz damit trainiert werden
            kann. Dafür wurden die Umrisse von Objekten in diesen Bildern
            markiert und beschriftet. So lernt das neuronale Netz zum Beispiel
            die Erkennung von Menschen neben einem Bus.
          </p>
          <a href="https://cocodataset.org/#explore?id=217928" class="card-link"
            >Mehr Beispiele aus dem CocoSSD Modell</a
          >
        </div>
      </div>
    </div>

    <div class="col-md-6 align-self-center">
      <div class="d-flex flex-column align-items-center">
        <div class="m-2 border">
          <video
            #video
            (loadeddata)="onLoadedData()"
            class="vid"
            autoplay
          ></video>
        </div>

        <div class="py-2">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="modelLoaded"
              [ngClass]="{ 'btn-success': modelLoaded }"
              (click)="loadModel()"
            >
              {{ modelLoaded ? "Bereit" : "Modell Laden" }}
              <span
                *ngIf="startedLoading && !modelLoaded"
                class="spinner-border spinner-border-sm"
              ></span>
            </button>
            <button
              class="btn btn-primary"
              [disabled]="!modelLoaded || webcamStarted"
              (click)="startCamera()"
            >
              {{ modelLoaded ? "Starte Webcam" : "Bitte Modell Laden" }}
            </button>
            <button
              class="btn btn-primary"
              [disabled]="!modelLoaded || !webcamStarted"
              (click)="changePredictionState()"
            >
              {{
                (detectionInterrupted ? "Starte" : "Stoppe") +
                  "
							Erkennung"
              }}
            </button>
          </div>
        </div>
        <div class="m-2">
          <canvas #canvas></canvas>
        </div>
      </div>
    </div>
  </div>
</div>
