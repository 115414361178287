<div class="h-100 d-flex" [routerLink]="routerLink">
  <div class="card custom-card my-3 flex-grow-1">
    <img
      *ngIf="!video && srcPath"
      [src]="srcPath"
      class="img-fluid"
      alt="thumbnail"
    />
    <video
      *ngIf="video && srcPath"
      [src]="srcPath"
      class="img-fluid"
      alt="thumbnail"
      autoplay
      muted
      loop
    ></video>
    <div class="card-body">
      <h5 class="card-title">{{ title }}</h5>
      <p class="card-text">{{ text }}</p>
    </div>
  </div>
</div>
