<div class="row my-3 align-items-center">

    <div class="col-md-8">
        <h4>Schritt 1: Datenbank laden</h4>
        <p>
            Wie auch bei dem überwachten maschinellen Lernen, muss zuerst die MNIST Datenbank geladen werden.
            Damit das Training schnell funktioniert, werden wir hierbei die MNIST Zahlen nicht kategorisieren, sondern
            als einen großen Haufen verwenden.
            Dadurch wird das Ergebnis zwar nicht so gut aussehen, dafür erreichen wir aber schneller eine Ausgabe, bei der Zahlen
            erkennbar sind.
            Wenn du ein Modell sehen möchtest, das lange auf den einzelnen Zahlen trainiert wurde, kannst du dir unten
            das vortrainierte MNIST GAN Modell anschauen und versuchen die vom Generator erzeugten Zahlen von den echten
            zu unterscheiden.
        </p>
    </div>
    <div class="col-md-4 text-center">
        <button type="submit" class="btn btn-primary" [ngClass]="{'btn-success': mnistReady}"
            (click)="loadMnist()">MNIST
            Datenbank Laden <span *ngIf="mnstdata&&!mnistReady" class="spinner-border spinner-border-sm"></span>
        </button>
    </div>
</div>

<div class="row my-3 align-items-center" *ngIf="mnistReady">
    <div class="col-md-8">
        <h4>Schritt 2: Neuronale Netze erstellen</h4>
        <p>
            Nun müssen wir die beiden neuronalen Netze erstellen.
            Ein Netz für den Generator und eins for den Diskriminator.
            Das Diskriminatornetz bekommt als Eingabe ein Bild einer MNIST Zahl, also
            <span><code>28 x 28 = 784</code></span> Pixel.
            Die 784 Pixel werden dann in drei Ebenen herunterskaliert, auf 200, 90 und zum Schluss eine einzige Ausgabe,
            welche dann als Wahrscheinlichkeit für die Echtheit verwendet wird.
            Das Generatornetz ist genau invers Aufgebaut. Als Eingabe bekommt das Netz einen Zufallsvektor mit 100
            Werten, welche auf die 784 Pixel hochskaliert werden.
        </p>
        <div class="btn-group-vertical d-flex my-2">
            <button type="submit" class="btn btn-primary" [disabled]="!mnistReady"
                [ngClass]="{'btn-success': weightsReady}" (click)="createWeights()">Tensorflow Variablen
                erstellen</button>
        </div>
    </div>
    <div class="col-md-4 text-center">
        <figure class="figure">
            <img src="assets/gan-images/gan-network-setup.png.webp" class="figure-img img-fluid rounded mb-1">
            <figcaption class="figure-caption">GAN Netzwerk Aufbau</figcaption>
        </figure>
    </div>
</div>
<div class="row my-3 align-items-center" *ngIf="weightsReady">
    <div class="col-lg-8">
        <h4>Schritt 3: Training</h4>
        <p>
            Während des Trainings beginnt der Generator zufällige Ausgaben zu erzeugen.
            Zu Beginn sieht das erzeugte Bild nach nichts aus, da der Generator noch nicht gelernt hat, wie MNIST Zahlen
            aussehen.
            Gleichermaßen hat der Diskriminator noch nicht gelernt, wie echte MNIST Zahlen aussehen.
            Da die beiden Netzwerke gegeneinander antreten, verbessern sie sich gegenseitig Schritt für Schritt.
        </p>
        <p>
            Für dieses relativ simple GAN Netzwerk sollten nach ca. 1000 Trainingsschritten Formen erkennbar sein, die
            MNIST Zahlen entsprechen. Nach allen 4000 Trainingsschritten kannst du beliebig viele künstliche MNIST-Bilder 
            erzeugen, die den echten Zahlen teilweise ähnlich sein sollten.
            Da das Training im Webbrowser durchgeführt wird, kann das Netzwerk leider keine perfekten Ergebnisse erzeugen,
            trotzdem wird hierdurch der Ablauf gut dargestellt.
        </p>
    </div>

    <div class="col-lg-4 text-center">
        <div class="flex-row d-flex flex-wrap">
            <button class="btn btn-primary flex-grow-1 m-1"
                [disabled]="!mnistReady || !weightsReady || trainStep === trainTotal" (click)="start(canvas)"
                [ngClass]="{'btn-success': trainStep === trainTotal}">
                {{trainStep === 0 ? 'Training Starten' : stopTraining ? 'Training Fortsetzen' : 'Training Pausieren' }}
                {{trainStep}} / {{trainTotal}}
            </button>
            <button class="btn btn-primary flex-grow-1 m-1" [disabled]="!mnistReady || !weightsReady"
                (click)="sample(canvas)">Neue Zahl generieren</button>
        </div>

        <div class="row px-1 my-2">
            <p>
                <ngb-progressbar [showValue]="true" type="success" [value]="trainStep" [max]="trainTotal">
                </ngb-progressbar>
            </p>
        </div>
        <div class="">
            <canvas #canvas></canvas>
        </div>
    </div>
</div>