import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent {
  isCollapsed = true;
  isSticky = false;

  constructor() {}

  @HostListener('window:scroll', ['$event'])
  checkStickyNav() {
    const logoRect = document
      .getElementById('ipn-logo')
      .getBoundingClientRect();

    this.isSticky = logoRect.height + logoRect.top < 1;
  }
}
