<div class="container pt-3">
    <div class="row mb-4">
        <div class="col-md-6 col-lg-7 col-xl-6">
            <div>
                <h3>Klassifikation mit der Webcam</h3>
                <p>
                    In der <a routerLink="/object">Objekterkennungs-Komponente</a> kannst du ein neuronales Netz,
                    das darauf trainiert ist, bestimmte Objekte in Bildern zu erkennen, bei der Arbeit beobachten. Woher
                    aber lernt ein neuronales Netz, welche Objekte auf einem Bild zu sehen sind? Diesen Vorgang nennt
                    man Klassifikation, weil die sichtbaren Objekte jeweils einer Klasse zugeordnet werden. Klassifikation ist 
                    eine Methode des überwachten Lernens.
                </p>

                <p>
                    In dieser Komponente kannst du deine Webcam zur Bildklassifikation einsetzen. Sobald die Webcam
                    gestartet wurde, kannst du verschiedene Gegenstände in die Kamera halten oder mit verschiedenen
                    Gesichtsausdrücken hineinschauen und dem neuronalen Netz in dem Textfeld mitteilen, was es gerade
                    sieht. Danach
                    kannst du testen, wie gut das neuronale Netz arbeitet, indem du ihm andere Gegenstände aus derselben
                    Klasse zeigst. Die Klasse kann zum Beispiel die Klasse Stift sein. Dann zeigst du erst einen Stift, 
                    schreibst es in das Textfeld und zeigst anschließend einen anderen Stift.
                </p>

                <div class="card my-2" style="max-width: 450px;">
                    <ngb-carousel *ngIf="images">
                        <ng-template ngbSlide>
                            <div class="picsum-img-wrapper">
                                <img [src]="images[0]" style="object-fit: cover; max-height: 250px;width: 100%;">
                            </div>
                            <div class="carousel-caption">
                            </div>
                        </ng-template>
                        <ng-template ngbSlide>
                            <div class="picsum-img-wrapper">
                                <img [src]="images[1]" style="object-fit: cover;max-height: 250px;width: 100%;">
                            </div>
                            <div class="carousel-caption">
                            </div>
                        </ng-template>
                        <ng-template ngbSlide>
                            <div class="picsum-img-wrapper">
                                <img [src]="images[2]" style="object-fit: cover;max-height: 250px;width: 100%;">
                            </div>
                            <div class="carousel-caption">
                            </div>
                        </ng-template>
                        <ng-template ngbSlide>
                            <div class="picsum-img-wrapper">
                                <img [src]="images[3]" style="object-fit: cover;max-height: 250px;width: 100%;">
                            </div>
                            <div class="carousel-caption">
                            </div>
                        </ng-template>
                    </ngb-carousel>

                    <div class="card-body">
                        <p class="card-text">
                            Drei Bilder, die Objekte zeigen, die als „Katze“ klassifiziert werden
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-lg-5 col-xl-6 d-flex flex-column align-items-center justify-content-center my-4">
            <video autoplay playsinline muted #video (loadeddata)="startStopNN()" class="border border-dark"></video>
        </div>
    </div>

    <div class="flex-row d-flex flex-wrap">
        <button type="submit" class="btn btn-primary flex-grow-1 m-1" [disabled]="model"
            [ngClass]="{'btn-success': model}" (click)="initiateModels()">{{model ? 'Bereit':'Modell Laden'}}
            <span *ngIf="startedLoading&&!model" class="spinner-border spinner-border-sm"></span>
        </button>
        <button class="btn btn-primary flex-grow-1 m-1" [disabled]="!model || webcamRunning"
            (click)="startWebcam()">{{model ? 'Starte Webcam':'Bitte Modell Laden'}}
        </button>
        <div class="flex-grow-1 m-1">
            <div class="input-group ">
                <input #input type="text" class="form-control" placeholder="Klasse Hinzufügen">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" (click)="addClass(input.value)">+</button>
                </div>
            </div>
        </div>
        <button class="btn btn-primary flex-grow-1 m-1" [disabled]="!webcamRunning" (click)="startStopNN()">{{
            (loopID ? 'Stoppe':'Starte')+ ' Klassifikation'}}</button>
        <button class="btn btn-primary flex-grow-1 m-1" (click)="resetClasses()">Alle
            Klassen
            Entfernen</button>
    </div>

    <div class="row my-5 " *ngIf="classes.size > 0">
        <div class="col-md-8">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Wahrscheinlichkeit</th>
                        <th scope="col"># Samples</th>
                        <th scope="col">Beispiele Hinzufügen</th>
                        <th scope="col">Klasse Entfernen</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let c of classes | keyvalue;let i=index;">
                        <th>{{ i+1 }}</th>
                        <td>{{ c.key }}</td>
                        <td>{{ c.value.pred | percent }}</td>
                        <td>{{ c.value.count }}</td>
                        <td>
                            <button type="button" class="btn btn-info btn-sm" [disabled]="!video || !loopID"
                                (mousedown)="currentSampleClass = c.key" (mouseup)="currentSampleClass = null"
                                (mouseleave)="currentSampleClass = null">Sample</button>
                        </td>
                        <td>
                            <button type="button" class="btn btn-danger btn-sm"
                                (click)="removeClass(c.key)">Entfernen</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-4 d-flex flex-column align-items-center mb-3">
            <h2>Klasse: <span class="font-italic font-weight-bolder">{{result}}</span></h2>
            <button type="button" class="btn btn-info btn-xl" (click)="solveMaze = !solveMaze">Labyrinth Lösen</button>
        </div>
    </div>
    <br>
    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="">
        <ngb-panel>
            <ng-template ngbPanelTitle>
                <span> <b>Wie funktioniert die Bildklassifikation?</b> </span>
            </ng-template>
            <ng-template ngbPanelContent>
                <p>
                    Vereinfacht ausgedrückt: Man gibt dem neuronalen Netzwerk ein Bild und die Information, was darauf
                    zu sehen ist. Das Netzwerk identifiziert nun selbstständig die Merkmale, die die Objekte dieser
                    Klasse
                    auszeichnen. Dies wiederholt man nun sehr oft und hofft, dass das Netzwerk die richtigen
                    Merkmale identifizieren wird.
                    In dieser Komponente kommt ein vortrainiertes neuronales Netzwerk namens MobileNet zum Einsatz, das
                    speziell für Bilderkennung auf mobilen Geräten trainiert wurde. Es zeichnet sich durch kompakte
                    Größe
                    und gute Klassifizierungsqualität aus.
                </p>
                <p>
                    Hierbei gibt MobileNet für jedes Bild, welches zum Beispiel durch eine Webcam präsentiert wird, eine Liste
                    von Zahlen zurück.
                    Deine Aufgabe ist es dann dieser Liste von Zahlen einen Namen zu geben.
                    Wenn das MobileNet Modell dann später ein neues Bild sieht, werden die Zahlen verglichen und es wird
                    der Name als Klasse gewählt,
                    dessen Zahlen am ähnlichsten zu dem neuen Bild sind.
                    Wenn das Modell genug Beispiele bekommt, ist es dann in der Lage die Klassen korrekt zuzuordnen.
                </p>
            </ng-template>
        </ngb-panel>
        <ngb-panel [disabled]="false">
            <ng-template ngbPanelTitle>
                <span> <b>Was dabei alles schief gehen kann</b> </span>
            </ng-template>
            <ng-template ngbPanelContent>
                <p>
                    Die Bilder, mit denen das neuronale Netzwerk trainiert wird, müssen sorgsam ausgewählt werden, damit
                    nicht die falschen Merkmale als maßgeblich identifiziert werden. Ein neuronales Netz von Google
                    sollte zum Beispiel anhand vieler Bilder lernen, was eine Hantel ist. Dann sollte das Netz selbst Bilder
                    von Hanteln erzeugen - und produzierte das hier:
                </p>
                <img src="assets/classify-images/dumbbells.png.webp" class="img-fluid border rounded mx-auto my-3 d-block">
                <p>
                    Offensichtlich waren der KI nur Bilder von Hanteln gezeigt worden, die von Menschen gehalten wurden
                    - und das neuronale Netzwerk identifizierte den Unterarm als wichtiges Merkmal der Hantel.
                    Klingt harmlos? 2015 geriet Google in die Kritik, nachdem Fotos dunkelhäutiger Menschen von Googles
                    Objekterkennungs-KI als „Gorilla“ identifiziert wurden - anscheinend war die KI nur mit weißen
                    Gesichtern zur Identifizierung von Menschen trainiert worden.
                </p>
            </ng-template>
        </ngb-panel>
        <ngb-panel [disabled]="false">
            <ng-template ngbPanelTitle>
                <span> <b>Transferlernen</b> </span>
            </ng-template>
            <ng-template ngbPanelContent>
                <p>
                    Transferlernen ist eine Technik im Bereich des maschinellen Lernens, bei der vorhandene Kenntnisse und
                    Erfahrungen aus der Lösung einer Aufgabe genutzt werden, um die Leistung bei der Lösung einer
                    ähnlichen, aber nicht identischen Aufgabe zu verbessern. Im Bereich der Bildklassifikation kann
                    Transferlernen verwendet werden, um Algorithmen mit vorhandenen Kenntnissen über visuelle Konzepte
                    und Objekte auszustatten, um die Genauigkeit und Leistung bei der Klassifikation neuer Bilder zu
                    erhöhen. Transferlernen kann eine nützliche Technik sein, um die Leistung von Algorithmen des 
                    maschinellen Lernens für die Bildklassifikation zu verbessern ohne, dass große Mengen an
                    Trainingsdaten benötigt werden. </p>
            </ng-template>
        </ngb-panel>
        <ngb-panel [disabled]="false">
            <ng-template ngbPanelTitle>
                <span> <b>Aufgabe 1</b> </span>
            </ng-template>
            <ng-template ngbPanelContent>
                <p>
                    Wie kann sichergestellt werden, dass das Transferlernen für die Bildklassifikation fair und
                    unvoreingenommen ist und keine Diskriminierung aufgrund von Merkmalen wie Geschlecht, Alter oder
                    Rasse verursacht?
                </p>
            </ng-template>
        </ngb-panel>
        <ngb-panel [disabled]="false">
            <ng-template ngbPanelTitle>
                <span> <b>Aufgabe 2</b> </span>
            </ng-template>
            <ng-template ngbPanelContent>
                <p>
                    Wie vergleicht sich die Verwendung des Transferlernens für die Bildklassifikation mit anderen
                    Methoden der Bildklassifikation, wie beispielsweise des Trainings von Neuronalen Netzen von Grund
                    auf?
                </p>
            </ng-template>
        </ngb-panel>
        <ngb-panel *ngIf="false">
            <ng-template ngbPanelTitle>
                <span> <b>Löse ein Labyrinth mit deiner Kamera</b> </span>
            </ng-template>
            <ng-template ngbPanelContent>
                Dieses neuronale Netz kannst du auch nutzen, um deinen Computer zum Beispiel mit Handbewegungen oder deiner
                Mimik zu steuern. Naja, nicht deinen ganzen Computer, aber wenigstens eine Spielfigur in einem kleinen
                Labyrinth. Wenn du vier Klassen „oben“, „unten“, „links“ und „rechts“ anlegst und auf „Labyrinth lösen“
                klickst, kannst du eine Figur darin steuern, indem du gezielt Objekte aus diesen vier Klassen in die
                Kamera zeigst. Schaffst du es, die Spielfigur ans Ziel zu bringen?

                <div class="col d-flex flex-column align-items-center mb-3">
                    <span class="badge badge-secondary">Füge Klassen hinzu, um zu navigieren!</span>
                    <div class="btn-group my-3" id="set-dir" role="group">
                        <button type="button" class="btn btn-secondary" (click)="addClass('links')">Links</button>
                        <button type="button" class="btn btn-secondary" (click)="addClass('oben')">Oben</button>
                        <button type="button" class="btn btn-secondary" (click)="addClass('unten')">Unten</button>
                        <button type="button" class="btn btn-secondary" (click)="addClass('rechts')">Rechts</button>
                        <button type="button" class="btn btn-secondary ml-2"
                            (click)="showMazeControl = !showMazeControl">Manuelle Steuerung</button>
                    </div>
                    <div class="btn-group my-3" role="group" *ngIf="showMazeControl">
                        <button type="button" class="btn btn-primary" (click)="emitEventToChild('links')">Links</button>
                        <button type="button" class="btn btn-primary" (click)="emitEventToChild('oben')">Oben</button>
                        <button type="button" class="btn btn-primary" (click)="emitEventToChild('unten')">Unten</button>
                        <button type="button" class="btn btn-primary"
                            (click)="emitEventToChild('rechts')">Rechts</button>
                    </div>
                    <app-maze [movementObservable]="moveSubject.asObservable()"></app-maze>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>

    <div class="row my-5" *ngIf="solveMaze">
        <div class="col d-flex flex-column align-items-center mb-3">
            <span class="badge badge-secondary">Füge Klassen hinzu, um zu navigieren!</span>
            <div class="btn-group my-3" id="set-dir" role="group">
                <button type="button" class="btn btn-secondary" (click)="addClass('links')">Links</button>
                <button type="button" class="btn btn-secondary" (click)="addClass('oben')">Oben</button>
                <button type="button" class="btn btn-secondary" (click)="addClass('unten')">Unten</button>
                <button type="button" class="btn btn-secondary" (click)="addClass('rechts')">Rechts</button>
                <button type="button" class="btn btn-secondary ml-2"
                    (click)="showMazeControl = !showMazeControl">Manuelle Steuerung</button>
            </div>
            <div class="btn-group my-3" role="group" *ngIf="showMazeControl">
                <button type="button" class="btn btn-primary" (click)="emitEventToChild('links')">Links</button>
                <button type="button" class="btn btn-primary" (click)="emitEventToChild('oben')">Oben</button>
                <button type="button" class="btn btn-primary" (click)="emitEventToChild('unten')">Unten</button>
                <button type="button" class="btn btn-primary" (click)="emitEventToChild('rechts')">Rechts</button>
            </div>
            <app-maze [movementObservable]="moveSubject.asObservable()"></app-maze>
        </div>
    </div>
</div>
<app-help-button>
    <app-classify-help></app-classify-help>
</app-help-button>