import { Component, OnInit } from '@angular/core';
import * as qna from '@tensorflow-models/qna';

@Component({
  selector: 'app-qna',
  templateUrl: './qna.component.html',
  styleUrls: ['./qna.component.css'],
})
export class QnaComponent implements OnInit {
  wikipediaURL =
    'https://en.wikipedia.org/w/api.php?action=query&origin=*&format=json&prop=extracts&redirects=1&formatversion=latest&explaintext=1&exsectionformat=plain&titles=';
  model: qna.QuestionAndAnswer;
  modelLoaded = false;
  loadingWiki = false;
  answerLoading = false;
  context = '';
  question = '';
  answer = '';
  onlyIntro = true;
  technicalHint = '';
  selectedWiki = '';
  missingArticleHint = '';

  constructor() {}

  async ngOnInit(): Promise<void> {
    this.model = await qna.load({ modelUrl: 'assets/text/qna/model.json' });
    this.modelLoaded = true;
  }

  async interpret(): Promise<void> {
    if (!this.modelLoaded) {
      console.warn('ignoring query, since model not loaded yet!');
      return;
    } else if (this.context.length < 1) {
      console.warn(
        'ignoring query, since given context does not contain any information!'
      );
      return;
    }

    this.answerLoading = true;

    // model.findAnswers seems to block the frontend thread. Without the timeout, the answerLoading loader will not appear.
    setTimeout(async () => {
      const answers = await this.model.findAnswers(this.question, this.context);
      if (answers.length < 1) {
        this.answer = 'Keine Antwort gefunden. Bitte formuliere die Frage um!';
      } else {
        this.answer = answers[0].text;
      }
      this.answerLoading = false;
    }, 100);
  }

  getWikiUrl(title: string, onlyIntro: boolean = true) {
    const encodedTitle = encodeURI(title);
    const introString = onlyIntro ? '&exintro=1' : '';
    return `${this.wikipediaURL}${encodedTitle}${introString}`;
  }

  async fetchWikiPage(title: string): Promise<string> {
    const url = this.getWikiUrl(title, this.onlyIntro);
    const response = await fetch(url);

    const parsedResponse: WikiResponse = await response.json();

    return parsedResponse.query.pages[0]?.extract;
  }

  async onWikiChange(): Promise<void> {
    if (
      this.loadingWiki ||
      this.answerLoading ||
      this.selectedWiki.length < 1
    ) {
      return;
    }
    this.loadingWiki = true;

    let text = await this.fetchWikiPage(this.selectedWiki);

    if (!text || text?.includes('may refer to')) {
      this.missingArticleHint = 'Dieser Artikel wurde nicht gefunden!';
      this.loadingWiki = false;
      return;
    } else {
      this.missingArticleHint = '';
    }

    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl');
    const textureBufferSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
    let textSize = new Blob([text]).size;
    if (textSize > textureBufferSize) {
      text = text.slice(0, textureBufferSize);
      this.technicalHint = `Der Text ist zu lang und wurde auf ${textureBufferSize} Zeichen verkürzt.`;
    } else {
      this.technicalHint = '';
    }

    // ´text´ can still be too large (in bytes), when there are multibyte characters in the text (e. g. [ or ]),
    // so we shorten it by 10 characters until the size in bytes fits.
    // Feels rather hacky, though.
    while (textSize > textureBufferSize) {
      text = text.slice(0, text.length - 10);
      textSize = new Blob([text]).size;
    }

    this.context = text;
    this.loadingWiki = false;
  }
}

interface WikiResponse {
  batchcomplete: boolean;
  query: {
    normalized?: Array<{
      fromencoded: boolean;
      from: string;
      to: string;
    }>;
    redirects?: Array<{ from: string; to: string }>;
    pages: Array<{
      pageid: number;
      ns: number;
      title: string;
      extract: string;
    }>;
  };
}
