<!-- Introductory section Word2Vec -->
<section>
  <div class="row mb-3">
    <h3>Word2Vec</h3>
    <p>
      Word2Vec ist ein Beispiel für ein Verfahren zur Erzeugung von Word
      Embeddings, das 2013 veröffentlicht wurde und auf der Annahme der
      "Distributional Hypothesis" basiert. Word2Vec verwendet eines von zwei
      neuronalen Netzen, Continuous Bag-of-Words und Skip-Gram, um während des
      Trainings des neuronalen Netzes Word Embeddings aus dem Kontext der Wörter
      in den Trainingsdaten zu lernen.
    </p>
  </div>
</section>

<!-- Text selection section -->
<section>
  <div class="row mb-3">
    <h4>Schritt 1: Text laden</h4>
    <p>
      Um Word Embeddings zu erzeugen, die Ähnlichkeiten und Bedeutungen von
      Wörtern sowie Beziehungen zwischen Wörtern gut wiedergeben, sind in der
      Regel große Textmengen aus verschiedenen Bereichen erforderlich, damit die
      einzelnen Wörter in möglichst unterschiedlichen Kontexten vorkommen und
      gelernt werden können. Die Auswahl der Trainingsdaten hat einen
      entscheidenden Einfluss auf die Qualität der erzeugten Word Embeddings und
      deren Verwendbarkeit für einen bestimmten Anwendungszweck.
    </p>
    <div class="col-md8">
      <select
        type="boolean"
        class="form-select inline-select"
        [(ngModel)]="useCustomText"
      >
        <option [ngValue]="false">Beispieltext verwenden</option>
        <option [ngValue]="true">Eigenen Text verwenden</option>
      </select>
    </div>
  </div>

  <div class="row justify-content-center mb-3" *ngIf="!useCustomText">
    <div class="col">
      <ngb-alert
        *ngIf="!hideWarn"
        [dismissible]="true"
        (closed)="hideWarn = true"
      >
        <strong>Hinweis!</strong> In realen Anwendungsfällen bestehen die
        Trainingsdaten in der Regel aus Tausenden von Texten mit Millionen von
        Wörtern. Da der Webbrowser jedoch nur über begrenzte Ressourcen verfügt,
        beschränken sich die Trainingsdaten in diesem Beispiel auf einen
        einzigen Text mit einigen hundert Wörtern.
      </ngb-alert>
    </div>
  </div>

  <div class="row justify-content-center mb-3" *ngIf="useCustomText">
    <div class="col">
      Füge hier deinen eigenen Text ein:
      <textarea
        spellcheck="false"
        class="form-control"
        rows="10"
        [(ngModel)]="customText"
      ></textarea>
    </div>
  </div>

  <div class="row justify-content-center mb-3">
    <div class="col-auto">
      <button
        type="button"
        class="btn btn-primary"
        [ngClass]="{ 'btn-success': textLoaded }"
        (click)="loadText()"
      >
        Text laden
        <span
          *ngIf="currentlyLoading && !steps[0]"
          class="spinner-border spinner-border-sm"
        ></span>
      </button>
    </div>
  </div>

  <div class="row mb-3" *ngIf="textLoaded">
    <h6>Textvorschau:</h6>
    <p>"{{ textPreview }}"</p>
  </div>
</section>

<!-- Model selection section -->
<section *ngIf="steps[0]">
  <div class="row mb-3">
    <h4>Schritt 2: Modell auswählen</h4>
    <p>
      Die beiden neuronalen Netze unterscheiden sich hauptsächlich in ihrem
      Trainingsziel sowie in der Aufbereitung der verwendeten Trainingsdaten.
      Beide neuronalen Netze verwenden Trainingsdaten, die aus einem Zielwort
      und Kontextwörtern in einem selbst gewählten Kontextfenster um das
      Zielwort herum generiert werden. Dabei wird beim Training des Continuous
      Bag of Words Modells versucht, das Zielwort aus den Kontextwörtern zu
      bestimmen, während beim Skip-Gram Modell die Bestimmung der einzelnen
      Kontextwörter im Vordergrund steht.
    </p>
  </div>

  <div class="row justify-content-center mt-4 mb-4">
    <div class="col-md-10">
      <!-- Continuous Bag-of-Words -->
      <ngb-accordion [closeOthers]="false" #acc="ngbAccordion">
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span>
              <h4>Continuous Bag-of-Words</h4>
            </span>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>
              Das Continuous Bag-of-Words Modell, kurz CBOW genannt, ist ein
              flaches neuronales Netz, d.h. es besteht nur aus wenigen
              Schichten. Die Eingabe des CBOW-Modells ist die Summe der
              One-Hot-kodierten Kontextwörter. Anhand dieser Eingabe ermittelt
              das Modell Wahrscheinlichkeiten für alle Wörter des Vokabulars,
              das Zielwort im gegebenen Kontext zu sein, und vergleicht dann die
              vorhergesagten Wahrscheinlichkeiten mit dem tatsächlichen
              Zielwort.
            </p>
            <figure class="figure">
              <img
                [src]="
                  playCBOWAnimation
                    ? 'assets/word-embeddings/ContinuousBagOfWords.webp'
                    : 'assets/word-embeddings/ContinuousBagOfWords.png'
                "
                alt=""
                class="img-fluid"
              />
              <figcaption class="figure-caption text-center">
                Visualisierung des Continuous Bag-of-Words Modells mit einem
                Kontextfenster der Größe 2.
              </figcaption>
            </figure>
            <button
              type="button"
              class="btn btn-primary m-1"
              (click)="playCBOWAnimation = !playCBOWAnimation"
            >
              Animation <span *ngIf="playCBOWAnimation">stoppen</span
              ><span *ngIf="!playCBOWAnimation">abspielen</span>
            </button>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <!-- Skip-Gram -->
      <ngb-accordion [closeOthers]="false" #acc="ngbAccordion">
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span>
              <h4>Skip-Gram</h4>
            </span>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>
              Das Skip-Gram Modell ist ebenfalls ein flaches neuronales Netz,
              das in vereinfachter Form die gleiche Architektur wie das
              Continuous Bag-of-Words Modell aufweist. Der Unterschied zum
              Continuous Bag-of-Words-Modell besteht darin, dass das
              One-Hot-kodierte Zielwort als Eingabe für die Vorhersage eines
              einzelnen Kontextworts verwendet wird. Das neuronale Netz muss
              also lernen, jedes der Kontextwörter zu einem Zielwort in gleicher
              Weise aus diesem Zielwort vorherzusagen.
            </p>
            <figure class="figure">
              <img
                [src]="
                  playSkipGramAnimation
                    ? 'assets/word-embeddings/SkipGram.webp'
                    : 'assets/word-embeddings/SkipGram.png'
                "
                alt=""
                class="img-fluid"
              />
              <figcaption class="figure-caption text-center">
                Visualisierung des Skip-Gram Modells mit einem Kontextfenster
                der Größe 2.
              </figcaption>
            </figure>
            <button
              type="button"
              class="btn btn-primary m-1"
              (click)="playSkipGramAnimation = !playSkipGramAnimation"
            >
              Animation <span *ngIf="playSkipGramAnimation">stoppen</span
              ><span *ngIf="!playSkipGramAnimation">abspielen</span>
            </button>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>

  <div class="row justify-content-center mb-3">
    <div class="col-auto">
      <button
        type="button"
        class="btn btn-primary m-1"
        [ngClass]="{ 'btn-success': modelSelection === 'cbow' }"
        (click)="loadModelData('cbow')"
      >
        Continuous Bag-of-Words Modell auswählen
        <span
          *ngIf="currentlyLoading && modelSelection === 'cbow' && !steps[1]"
          class="spinner-border spinner-border-sm"
        ></span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [ngClass]="{ 'btn-success': modelSelection === 'skip-gram' }"
        (click)="loadModelData('skip-gram')"
      >
        Skip-Gram Modell auswählen
        <span
          *ngIf="
            currentlyLoading && modelSelection === 'skip-gram' && !steps[1]
          "
          class="spinner-border spinner-border-sm"
        ></span>
      </button>
    </div>
  </div>
</section>

<!-- Model training section -->
<section *ngIf="steps[1]">
  <div class="row justify-content-center mb-3">
    <h4>Schritt 3: Training</h4>
    <p>
      Nun beginnt der eigentliche Lern- und Trainingsprozess. Für das Training
      des neuronalen Netzes können einige Parameter eingestellt werden, die den
      Verlauf und die Ergebnisse des Trainings beeinflussen.
    </p>
    <p>
      Es können neben der Batchsize
      <select
        class="form-select form-select-sm mx-2 inline-select"
        type="number"
        [(ngModel)]="selectedBatchSize"
      >
        <option disabled>Batchsize</option>
        <option *ngFor="let x of availableBatchSizes" [ngValue]="x">
          {{ x }}
        </option>
      </select>
      , welche angibt, wie viele Beispiele das neuronale Netz in einem
      Trainingsschritt verarbeiten soll, und der Anzahl der Epochen
      <select
        class="form-select form-select-sm mx-2 inline-select"
        type="number"
        [(ngModel)]="selectedNumEpochs"
      >
        <option disabled>Epochen</option>
        <option *ngFor="let x of availableEpochs" [ngValue]="x">
          {{ x }}
        </option>
      </select>
      , welche bestimmt wie oft die Trainingsdaten im gesamten Trainingsprozess
      durchlaufen werden sollen, beim Training von Word Embeddings auch die
      Dimension der Word Embeddings
      <select
        class="form-select form-select-sm mx-2 inline-select"
        type="number"
        [(ngModel)]="selectedEmbeddingDim"
      >
        <option disabled>Embedding Dimension</option>
        <option *ngFor="let x of availableEmbeddingDims" [ngValue]="x">
          {{ x }}
        </option>
      </select>
      , d.h. die Anzahl der Elemente der einzelnen Word Embedding Vektoren, frei
      gewählt werden, was beeinflusst wie viele Informationen abgebildet werden
      können. Für ein Beispiel mit wenigen Trainingsdaten, wie es hier der Fall
      ist, sind bereits niedrige Dimensionen ausreichend. In realen
      Anwendungsfällen werden jedoch meist Word Embeddings mit mehreren hundert
      Dimensionen verwendet, abhängig von der Menge an Trainingsdaten und dem
      Anwendungszweck.
    </p>

    <div class="col-auto">
      <button
        type="button"
        class="btn btn-primary m-1"
        [ngClass]="{ 'btn-success': trainingDone && !trainingRunning }"
        (click)="trainModel()"
      >
        Modell trainieren
        <span
          *ngIf="trainingRunning"
          class="spinner-border spinner-border-sm"
        ></span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        *ngIf="trainingRunning"
        (click)="stopTraining()"
      >
        Training vorzeitig beenden
      </button>
    </div>
  </div>
</section>

<!-- Training visualization section -->
<section *ngIf="steps[2]">
  <div class="col text-center">
    <canvas
      baseChart
      width="400"
      height="200"
      [data]="lineChartData"
      [options]="lineChartOptions"
      [type]="lineChartType"
    ></canvas>
    <div
      class="row justify-content-center my-2"
      *ngIf="trainingRunning || trainingDone"
    >
      <p>
        <ngb-progressbar
          [showValue]="true"
          type="success"
          [value]="currentEpoch"
          [max]="selectedNumEpochs"
        ></ngb-progressbar>
      </p>
    </div>
  </div>
</section>
