<div id="intro-section">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="mb-4">
          <h1>Herzlich willkommen im KI-Labor!</h1>
          <p>
            Diese Webseite ermöglicht eine praktische Einführung in das Gebiet
            der Künstlichen Intelligenz (KI). Im Inhalt knüpft diese Seite an den Massive Open Online Course
            Elements of AI an, aber alle Übungen können auch ohne Kenntnis des
            MOOC bearbeitet werden.
          </p>
        </div>
        <div>
          <h3>Wer, was, warum?</h3>
          <p>
            Entwickelt wurde diese Seite an der
            Christian-Albrechts-Universität zu Kiel als Teil einer
            Bachelorarbeit. Nun wird diese Webseite von der Arbeitsgruppe für
            Didaktik weiterentwickelt.
          </p>
          <p>
            Insgesamt bietet diese Seite eine Vielzahl an Lerneinheiten, die
            eine Vielzahl von Bereichen der künstlichen Intelligenz abdecken.
            Mehr dazu kannst du unten erfahren.
          </p>
          <p>
            Das Ziel ist es, eine begleitende Lernumgebung zu ermöglichen, womit
            Schüler den praktischen Umgang mit maschinellem Lernen erfahren
            können, während Sie den MOOC
            <a class="anchor-on-blue" href="https://course.elementsofai.com/de/"
              >Elements of AI</a
            >
            bearbeiten.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-5">
  <div class="container">
    <div>
      <h2>Welche Themen werden hier behandelt?</h2>
      <p>
        Maschinelles Lernen umfasst drei große Teilgebiete. Auf dieser Seite findest du zwölf
        Lerneinheiten, die unter anderem die Teilgebiete maschinellen Lernens „Überwachtes Lernen“, „Unüberwachtes Lernen“ und
        „Bestärkendes Lernen“ thematisieren. Einige Lerneinheiten behandeln das Thema tiefes Lernen (Englisch: Deep Learning). 
        Viel Spaß! 
      </p>
    </div>

    <div>
      <span
        *ngFor="let tag of allTags; index as i"
        (click)="addOrRemove(tag)"
        [ngClass]="{
          'bg-success': activeTags.includes(tag),
          'bg-danger': excludedTags.includes(tag)
        }"
        class="badge filter-pill-badge bg-primary"
        >{{ tag | titlecase }}</span
      >
    </div>
    <div class="d-flex justify-content-evenly row">
      <app-card
        *ngFor="
          let card of cardsData
            | callback : filterCards : activeTags : excludedTags
        "
        [title]="card.title"
        [text]="card.text"
        [srcPath]="card.srcPath"
        [video]="card.video"
        [routerLink]="card.routerLink"
      >
      </app-card>
    </div>
  </div>
</div>
