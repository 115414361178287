<div class="container pt-3">
  <!-- Introductory section -->
  <section>
    <!-- Introduction -->
    <div class="row mb-3">
      <h3>Word Embeddings</h3>
      <p>
        Word Embeddings sind ein grundlegendes Konzept in der Verarbeitung
        natürlicher Sprache und werden in vielen modernen Anwendungen der
        künstlichen Intelligenz verwendet. Sie stellen eine Möglichkeit dar,
        Wörter in einer maschinenverständlichen Form darzustellen, die
        Ähnlichkeiten und Bedeutungen von Wörtern sowie Beziehungen zwischen
        Wörtern erfassen kann. Die Entwicklung dieser Technologie hat die Art
        und Weise verändert, wie Computer Text verstehen und interpretieren
        können und hat beispielsweise zu bedeutenden Fortschritten bei der
        maschinellen Übersetzung, der Informationssuche und vielen anderen
        Bereichen der Verarbeitung natürlicher Sprache geführt.
      </p>
    </div>

    <!-- Integer encoding-->
    <div class="row mb-3 justify-content-center">
      <div class="col-12">
        <h3>Integer-Kodierung</h3>
        Vor der Entwicklung von Word Embeddings wurden Methoden wie die
        Integer-Kodierung genutzt, um Texte für Maschinen verständlich zu
        machen. Dabei wird jedem Wort des Vokabulars, also den einzigartigen
        Wörtern der zu verarbeitenden Texte, eine eindeutige Zahl zugeordnet.
        Diese Methode hat den Vorteil, dass sie relativ einfach zu
        implementieren und im Vergleich mit anderen Methoden sehr effizient ist,
        allerdings beinhaltet diese Form der Repräsentation keine Informationen
        über die verarbeiteten Wörter.
      </div>
      <div class="col-8 justify-content-center">
        <figure class="figure">
          <img
            src="assets/word-embeddings/IntegerKodierung.png"
            alt=""
            class="img-fluid"
          />
          <figcaption class="figure-caption text-center">
            Beispiel einer Integer-Kodierung eines einzelnen Satzes.
          </figcaption>
        </figure>
      </div>
    </div>

    <!-- One-hot encoding -->
    <div class="row mb-3 justify-content-center">
      <div class="col-12">
        <h3>One-Hot-Kodierung</h3>
        Eine weitere Möglichkeit, Texte maschinenlesbar darzustellen, ist die
        One-Hot-Kodierung. Anstatt jedem Wort des Vokabulars eine Zahl
        zuzuordnen, wird jedem Wort ein Vektor, also eine Liste von Zahlen
        zugeordnet, in der genau eine Zahl 1 ist und die restlichen Zahlen 0
        sind. Die Position der 1 im Vektor ist für jedes Wort eindeutig und gibt
        somit an, um welches Wort es sich handelt. Diese Methode ist zwar
        weniger effizient als die Integer-Kodierung, wird aber zum Beispiel bei der
        Erzeugung von Word Embeddings verwendet.
      </div>
      <div class="col-md-8">
        <figure class="figure">
          <img
            src="assets/word-embeddings/OneHotKodierung.png"
            alt=""
            class="img-fluid"
          />
          <figcaption class="figure-caption text-center">
            Beispiel einer One-Hot-Kodierung des gleichen Satzes.
          </figcaption>
        </figure>
      </div>
    </div>

    <!-- Word embeddings -->
    <div class="row mb-3 justify-content-center">
      <h3>Word Embeddings</h3>
      <p>
        Word Embeddings sind eine Technik, die es ermöglicht, Bedeutungen und
        Beziehungen zwischen Wörtern in einer für Maschinen verständlichen
        Repräsentation der Wörter abzubilden und damit für den Computer
        verständlich zu machen. Auch Word Embeddings sind Vektoren, die im
        Gegensatz zu One-Hot-Vektoren reelle Zahlen als Elemente enthalten,
        welche im Trainingsprozess eines neuronalen Netzes aus den verwendeten
        Trainingsdaten gelernt werden.
      </p>
      <p>
        Das Konzept der Word Embeddings basiert auf der "Distributional
        Hypothesis", die vereinfacht besagt, dass Wörter, die häufig im gleichen
        Kontext verwendet werden, auch ähnliche Bedeutungen haben. Nach dieser
        Hypothese erhalten Wortpaare mit ähnlichen Bedeutungen ähnliche Word
        Embeddings und es ist möglich, Beziehungen zwischen ähnlichen Wortpaaren
        zu übertragen. Andererseits sollten sich die Word Embeddings von
        Wörtern, die in keiner Beziehung zueinander stehen, deutlich
        unterscheiden.
      </p>
      <div class="col-8">
        <figure class="figure">
          <img
            src="assets/word-embeddings/BeispielEmbeddings.png"
            alt=""
            class="img-fluid"
          />
          <figcaption class="figure-caption text-center">
            Beispiele für Word Embeddings, die ähnliche Wörter und Beziehungen
            zwischen Wörtern abbilden.
          </figcaption>
        </figure>
      </div>
      <p>
        Jedes Element der Word Embeddings enthält Informationen über die
        Eigenschaften des Wortes, welche sich aus dem Kontext des Wortes
        innerhalb der verwendeten Texte ergeben. Welche Eigenschaften genau in
        einem Element des Word Embeddings abgebildet werden, ist aufgrund der
        Vielzahl der Berechnungen innerhalb eines neuronalen Netzes nicht genau
        nachvollziehbar. Es ist jedoch davon auszugehen, dass jedes Element die
        Kombination mehrerer Eigenschaften repräsentiert.
      </p>
    </div>

    <!-- First group of tasks -->
    <div class="row justify-content-center mt-4 mb-4">
      <div class="col-md-8">
        <ngb-accordion [closeOthers]="false" #acc="ngbAccordion">
          <ngb-panel>
            <ng-template ngbPanelTitle>
              <span>
                <h4>Aufgabe 1</h4>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Nenne dir bekannte Beispiele für Beziehungen zwischen Wortpaaren
              aus der deutschen Sprache, die von Word Embeddings abgebildet
              werden könnten.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <ngb-accordion [closeOthers]="false" #acc="ngbAccordion">
          <ngb-panel>
            <ng-template ngbPanelTitle>
              <span>
                <h4>Aufgabe 2</h4>
              </span>
            </ng-template>
            <ng-template ngbPanelContent>
              Erläutere die Vorteile, die Word Embeddings gegenüber simpleren
              Varianten der Repräsentation von Wörtern in maschinenlesbarer Form
              haben.
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>

    <!-- Next section button -->
    <div class="row justify-content-center mb-3">
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-primary"
          [ngClass]="{ 'btn-success': stages[0] }"
          (click)="stages[0] = true"
        >
          Eigene Word Embeddings trainieren
        </button>
      </div>
    </div>
  </section>

  <!-- Training section -->
  <section *ngIf="stages[0]">
    <app-train-embedding-models
      (embeddingsTrained)="loadEmbeddings($event)"
    ></app-train-embedding-models>
  </section>

  <!-- Visualization section -->
  <section *ngIf="stages[1]">
    <app-embedding-projector
      [embeddings]="embeddings"
      [words]="words"
    ></app-embedding-projector>
  </section>
</div>
