<header>
  <div class="container py-4" id="ipn-logo">
    <div class="ipn-logo">
      <div class="big logo">
        <a href="/">
          <img
            id="navbar-logo"
            title="KI-Labor"
            src="/assets/branding/Logo_KI-LABOR_cmyk_100mm.svg"
            alt="KI-Labor"
            class="figure-img img-fluid rounded"
          />
        </a>
      </div>
    </div>
  </div>
  <div
    id="sticky-navbar-spacer"
    class="p-2 text-white"
    [class.d-none]="!isSticky === true"
  ></div>
  <nav
    id="navbar"
    class="navbar navbar-expand-xl navbar py-0"
    [class.sticky-top]="isSticky === true"
  >
    <div class="container">
      <button
        class="navbar-toggler"
        type="button"
        (click)="isCollapsed = !isCollapsed"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed">
        <div class="navbar-nav justify-content-between w-100">
          <li class="nav-item" role="presentation" routerLinkActive="active">
            <a class="nav-link" routerLink="mnist" (click)="isCollapsed = true"
              >MNIST Zahlenerkennung</a
            >
          </li>
          <div ngbDropdown style="cursor: pointer">
            <a class="nav-link" ngbDropdownToggle>GANs</a>
            <div ngbDropdownMenu>
              <button
                ngbDropdownItem
                routerLink="gan-training"
                (click)="isCollapsed = true"
              >
                Training mit MNIST
              </button>
              <button
                ngbDropdownItem
                routerLink="gan-transfer"
                (click)="isCollapsed = true"
              >
                Style Transfer
              </button>
            </div>
          </div>

          <div ngbDropdown style="cursor: pointer">
            <a class="nav-link" ngbDropdownToggle>Text</a>
            <div ngbDropdownMenu>
              <button
                ngbDropdownItem
                routerLink="text-toxic"
                (click)="isCollapsed = true"
              >
                Beleidigungserkennung
              </button>
              <button
                ngbDropdownItem
                routerLink="text-qna"
                (click)="isCollapsed = true"
              >
                Text-Interpretation
              </button>
              <button
                ngbDropdownItem
                routerLink="text-word-embeddings"
                (click)="isCollapsed = true"
              >
                Word Embeddings
              </button>
            </div>
          </div>

          <li class="nav-item" role="presentation" routerLinkActive="active">
            <a
              class="nav-link"
              routerLink="classify"
              (click)="isCollapsed = true"
              >Klassifikation mit der Webcam</a
            >
          </li>

          <li class="nav-item" role="presentation" routerLinkActive="active">
            <a class="nav-link" routerLink="object" (click)="isCollapsed = true"
              >Objekterkennung</a
            >
          </li>

          <div ngbDropdown style="cursor: pointer">
            <a class="nav-link" ngbDropdownToggle>Bestärkendes Lernen</a>
            <div ngbDropdownMenu>
              <button
                ngbDropdownItem
                routerLink="tictactoe"
                (click)="isCollapsed = true"
              >
                Tic Tac Toe
              </button>
              <button
                ngbDropdownItem
                routerLink="cartpole"
                (click)="isCollapsed = true"
              >
                CartPole
              </button>
            </div>
          </div>

          <li class="nav-item" role="presentation" routerLinkActive="active">
            <a
              class="nav-link"
              routerLink="perceptron"
              (click)="isCollapsed = true"
              >Perceptron</a
            >
          </li>

          <li class="nav-item" role="presentation" routerLinkActive="active">
            <a
              class="nav-link"
              routerLink="translate"
              (click)="isCollapsed = true"
              >Übersetzer</a
            >
          </li>

          <li class="nav-item" role="presentation" routerLinkActive="active">
            <a
              class="nav-link"
              routerLink="notebooks"
              (click)="isCollapsed = true"
              >Python Notebooks</a
            >
          </li>
        </div>
      </div>
    </div>
  </nav>
</header>
