import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { cosine } from 'ml-distance/lib/similarities';
import * as tf from '@tensorflow/tfjs';
import { EmbeddingProjectorService } from './embedding-projector.service';
import { PCA } from 'ml-pca';

@Component({
  selector: 'app-embedding-projector',
  templateUrl: './embedding-projector.component.html',
  styleUrls: ['./embedding-projector.component.css'],
})
export class EmbeddingProjectorComponent implements AfterViewInit {
  // Variables to store input of embeddings and words from the WordEmbeddingsComponent
  @Input() embeddings: any;
  @Input() words: string[];

  // Reference to the canvas for the embedding projector
  @ViewChild('embeddingProjector', { static: true })
  embeddingProjectorCanvas: ElementRef<HTMLCanvasElement>;

  currentlyLoading: boolean = false;

  // Variable to store embeddings after dimensionality reduction
  reducedEmbeddings: any;

  selectedWord: string;
  similaritiesCalculated: boolean = false;
  mostSimilarWords: string[] = [];

  wordArithmeticBase: string;
  wordArithmeticSub: string;
  wordArithmeticAdd: string;
  wordArithmeticResult: string = '';
  wordArtihmeticCalculated: boolean = false;
  wordsCalculated: boolean = false;
  mostSimilarCalculatedWords: string[] = [];

  // Variable to store selection of which embeddings to load
  use2DEmbeddings: boolean = false;

  // Variable to indicate if the warning was already hidden
  hideWarn: boolean = false;

  // Variables to control whether to use the sections to do calculations with the word embeddings
  showSimilarWordsSection: boolean = true;
  showCalculateWordsSection: boolean = true;

  // Load other visualization in embedding projector
  loadEmbeddingVisualization() {
    this.reduceDimensionality();
    this.projectorService.updateWordEmbeddings(
      this.reducedEmbeddings.data,
      this.words
    );
    if (this.use2DEmbeddings) {
      this.projectorService.destroy();
      this.projectorService.load2D();
    } else {
      this.projectorService.destroy();
      this.projectorService.load3D();
    }
  }

  // Calculate the most similar words to the select word
  calculateSimilarities(): void {
    if (this.selectedWord) {
      const mostSimilarWords: string[] = [];
      this.currentlyLoading = true;
      this.similaritiesCalculated = false;
      const index: number = this.words.indexOf(this.selectedWord);
      const wordList: string[] = [...this.words];
      wordList.splice(index, 1);
      const similarities: number[] = [];

      for (let i = 0; i < wordList.length; i++) {
        if (i !== index) {
          similarities.push(cosine(this.embeddings[index], this.embeddings[i]));
        }
      }

      for (let i = 0; i < 10; i++) {
        const maxIndex: number = similarities.indexOf(
          Math.max(...similarities)
        );
        mostSimilarWords.push(wordList[maxIndex]);
        wordList.splice(maxIndex, 1);
        similarities.splice(maxIndex, 1);
      }

      this.mostSimilarWords = mostSimilarWords;
      this.similaritiesCalculated = true;
      this.currentlyLoading = false;
    }
  }

  // Calculate word based on the selected words for vector aritmetic
  async calculateWords(): Promise<void> {
    if (
      this.wordArithmeticBase &&
      this.wordArithmeticSub &&
      this.wordArithmeticAdd
    ) {
      this.currentlyLoading = true;
      this.wordsCalculated = false;

      const indexBase: number = this.words.indexOf(this.wordArithmeticBase);
      const indexSub: number = this.words.indexOf(this.wordArithmeticSub);
      const indexAdd: number = this.words.indexOf(this.wordArithmeticAdd);

      const baseTensor: tf.Tensor = tf.tensor(this.embeddings[indexBase]);
      const subTensor: tf.Tensor = tf.tensor(this.embeddings[indexSub]);
      const addTensor: tf.Tensor = tf.tensor(this.embeddings[indexAdd]);

      // Calculate the result of the vector arithmetic
      const calculatedTensor: tf.Tensor = baseTensor
        .sub(subTensor)
        .add(addTensor);

      const calculatedArray: any = await calculatedTensor.array();

      baseTensor.dispose();
      subTensor.dispose();
      addTensor.dispose();
      calculatedTensor.dispose();

      const mostSimilarWords: string[] = [];
      const wordList: string[] = [...this.words];
      // Delete the words from the word list that where used to calculate the new word
      wordList.splice(indexBase, 1);
      wordList.splice(indexSub, 1);
      wordList.splice(indexAdd, 1);
      const similarities: number[] = [];

      for (let i = 0; i < wordList.length; i++) {
        // Exclude the similarities of the words used to calculate the result
        if (i !== indexBase && i !== indexSub && i !== indexAdd) {
          similarities.push(cosine(calculatedArray, this.embeddings[i]));
        }
      }

      for (let i = 0; i < 10; i++) {
        const maxIndex: number = similarities.indexOf(
          Math.max(...similarities)
        );
        mostSimilarWords.push(wordList[maxIndex]);
        wordList.splice(maxIndex, 1);
        similarities.splice(maxIndex, 1);
      }

      this.mostSimilarCalculatedWords = mostSimilarWords;
      this.wordsCalculated = true;
      this.currentlyLoading = false;
    }
  }

  // Reduce dimensionality of the embeddings
  reduceDimensionality(): void {
    const options: any = {
      isCovarianceMatrix: false,
      method: 'SVD',
      nCompNIPALS: 0,
      center: true,
      scale: false,
      ignoreZeroVariance: false,
    };

    const pca: PCA = new PCA(this.embeddings, options);

    this.reducedEmbeddings = pca.predict(this.embeddings, {
      nComponents: 3,
    });
  }

  constructor(private projectorService: EmbeddingProjectorService) {}

  ngAfterViewInit(): void {
    this.reduceDimensionality();

    this.projectorService.init(
      this.embeddingProjectorCanvas,
      this.reducedEmbeddings.data,
      this.words
    );
  }
}
