<div class="container pt-3">
  <div class="row">
    <div class="col-md-7">
      <h3>Text-Interpretation</h3>
      <p>
        Im Jahr 2022 fand ein Durchbruch in generativen Textmodellen statt. Auf
        einmal war es möglich mit künstlichen neuronalen Netzwerken beliebige
        Texte zu erzeugen und zu deuten, oft auf einem hohen Niveau - ein zuvor
        unterrepräsentierter Bereich der künstlichen Intelligenz.
      </p>
      <p>
        Die treibende technische Kraft hinter diesen abrupten Fortschritten in
        textbasierter künstlicher Intelligenz ist eine neue Art von
        Deep-Learning Architekturen. Diese werden Transformer genannt und
        zeichnen sich dadurch aus, dass sie sich effizient parallel trainieren
        lassen, und ideal für sequenzielle Daten geeignet sind.
      </p>
      <p>
        Transformer sind in der Lage, Eingabedaten zu verarbeiten, wobei der
        lokale und zeitliche Kontext der Eingabedaten in dem Modell mit
        einbezogen wird. Der entscheidende Unterschied zu anderen Modellen, die
        wir uns bislang angeschaut haben, und auch der Grund, weshalb die
        Verarbeitung von natürlicher Sprache sich traditionell als sehr
        schwierig erwiesen hat, ist, dass ein Modell Kontext benötigt, um zum Beispiel
        die Bedeutung eines Wortes in einem Satz zu verstehen. Angenommen ein
        Modell bekommt nacheinander die ersten fünf Wörter des folgenden Satzes
        als Eingabe: <code class="mx-3">Der rote Apfel fiel vom Baum.</code>
        Nun fragen wir das Modell, welches Wort als nächstes folgt. Ohne den
        Kontext der vorherigen Wörter ist es unmöglich für das Modell eine
        akkurate Vorhersage für das nächste Wort zu erzeugen.
      </p>
      <p>
        Wenn wir dies mit unserem MNIST Modell vergleichen, erkennen wir, dass
        hier keinerlei Kontext existiert. Um eine handgeschriebene Zahl zu
        erkennen, sind keine Informationen über die letzte Zahl von nöten.
      </p>
    </div>
    <div class="col-md-5 align-self-center">
      <div class="d-flex flex-column align-items-center">
        <figure class="figure">
          <img
            src="assets/text/qna/nnet.jpg.webp"
            class="figure-img img-fluid rounded"
          />
          <figcaption class="figure-caption">
            Illustration eines neuronalen Netzes von Mike MacKenzie
            <a href="https://creativecommons.org/licenses/by/2.0/">CC BY 2.0</a>
          </figcaption>
        </figure>
      </div>
    </div>
  </div>

  <div class="row my-3 flex-md-row-reverse">
    <div class="col-md-6">
      <h4>ChatGPT und Co.</h4>
      <p>
        Aktuell versuchen sich diverse Bildungseinrichtungen mit der Ankunft von
        <a href="https://chat.openai.com">ChatGPT</a> zu arrangieren. ChatGPT
        ist ein gererativer pre-trained Transformer Chatbot von OpenAI. Chatbot
        bedeutet, dass Nutzer dem Modell eine Aufgabe stellen können woraufhin das
        Modell einen Text als Ausgabe schreibt. Das Problem liegt dabei darin,
        dass die Ausgaben von ChatGPT teilweise mit denen eines Schülers
        mithalten können, wodurch sich ethische Bedenken ergeben.
      </p>
      <p>
        Das ChatGPT Modell wurde auf einem großen Teil des Internets über
        mehrere Monate auf Tausenden Grafikkarten trainiert und hat 175
        Milliarden Parameter. Wenn wir ChatGPT und unser MNIST Modell mit 35
        Tausend Parametern vergleichen, wird schnell klar, dass wir ein solches
        Modell nicht ausschließlich im Browser ausführen oder trainieren können.
        Aktuell kostet ChatGPT $100,000 pro Tag, um die Anfragen der Nutzer auf
        speziellen Servern zu verarbeiten.
      </p>
      <h5>Anleitung</h5>
      <p>
        Als Alternative kannst du hier einen Englischen Wikipedia Artikel laden
        - optional nur die Einführung - und eine Frage stellen, die unser Modell
        dann anhand des Textes zu beantworten versucht.
        <br />
        Dies ist konzeptionell der gleiche Ansatz, den andere Lösungen wie
        ChatGPT verwenden, ohne dass wir Millionen für das Training ausgeben
        müssen.
      </p>
    </div>
    <div class="col-md-6">
      <p>
        Wähle einen Artikel aus der Liste, oder kopiere den Titel eines Artikels
        aus der englischen Wikipedia in das Feld.
      </p>
      <div class="input-group mb-3">
        <div class="form-floating">
          <input
            class="form-control"
            id="floatingInputGroup1"
            list="datalistOptions"
            placeholder="Username"
            [(ngModel)]="selectedWiki"
          />
          <label for="floatingInputGroup1">Artikel auswählen</label>
          <datalist id="datalistOptions">
            <option value="Google"></option>
            <option value="Nikola Tesla"></option>
            <option value="New York City"></option>
            <option value="World War 2"></option>
          </datalist>
        </div>

        <button
          class="btn btn-outline-success wikiloadbutton"
          type="button"
          id="button-addon2"
          [disabled]="loadingWiki || answerLoading || !selectedWiki"
          (click)="onWikiChange()"
        >
          <span
            class="spinner-border spinner-border-sm mx-2"
            [ngClass]="loadingWiki ? '' : 'd-none'"
            role="status"
            aria-hidden="true"
          ></span>
          <span [ngClass]="loadingWiki ? 'd-none' : ''"> Laden </span>
        </button>
      </div>
      <p class="text-danger">{{ missingArticleHint }}</p>
      <div class="form-check form-switch mb-3">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckChecked"
          [(ngModel)]="onlyIntro"
          [disabled]="loadingWiki || answerLoading"
        />
        <label class="form-check-label" for="flexSwitchCheckChecked"
          >Nur Einführung laden</label
        >
      </div>

      <div class="mb-3">
        <label for="exampleFormControlTextarea1" class="form-label"
          >Artikelinhalt (Kontext)</label
        >

        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          [(ngModel)]="context"
        ></textarea>
        <p class="text-danger">{{ technicalHint }}</p>
      </div>

      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Frage (auf Englisch)</label
        >
        <input
          type="text"
          class="form-control"
          id="exampleFormControlInput1"
          [(ngModel)]="question"
        />
      </div>

      <button
        type="submit"
        class="btn btn-primary mb-3"
        placement="top"
        ngbTooltip="Context und Frage müssen in Englisch gestellt werden."
        (click)="interpret()"
        [disabled]="!modelLoaded || loadingWiki || !question"
      >
        Interpretieren
      </button>
      <span
        class="spinner-border spinner-border-sm mx-2"
        role="status"
        aria-hidden="true"
        [ngClass]="answerLoading ? '' : 'd-none'"
      ></span>
      <div class="mb-3">
        <label for="exampleFormControlTextarea1" class="form-label"
          >Antwort</label
        >
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          [(ngModel)]="answer"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="row justify-content-center mt-5 mb-3">
    <div class="col-md-8">
      <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="">
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span>
              <h4>Aufgabe 1</h4>
            </span>
          </ng-template>
          <ng-template ngbPanelContent>
            Lade den Wikipedia Artikel von Google und frage das Modell: "When
            was google founded?". Anschließend lade den Wikipedia Artikel "World
            War 2" und frage das Modell: "Who was part of the axis?". Sind die
            Antworten korrekt?
          </ng-template>
        </ngb-panel>
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span>
              <h4>Aufgabe 2</h4>
            </span>
          </ng-template>
          <ng-template ngbPanelContent>
            Lade einen anderen englischen Wikipedia Artikel deiner Wahl und
            suche dir einen konkreten Datenpunkt aus dem Artikel. Überprüfe
            anschließend, ob das Modell eine Frage zu diesem Datenpunkt korrekt
            beantworten kann.
          </ng-template>
        </ngb-panel>
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span>
              <h4>Aufgabe 3</h4>
            </span>
          </ng-template>
          <ng-template ngbPanelContent>
            Obwohl generative Modelle sehr beeindruckende Ergebnisse produzieren
            können, müssen diese Ergebnisse nicht immer korrekt sein. Ein
            ChatGPT Kokurrent von Google hat zuletzt Schlagzeilen gemacht, weil
            er
            <a
              href="https://www.heise.de/news/Google-Bard-Fehlerhafte-Antwort-der-KI-laesst-Experten-und-Anleger-zweifeln-7489896.html"
              >fehlerhafte Antworten erzeugt hat</a
            >. Welche Probleme entstehen, wenn wir generative Modelle verwenden,
            ohne die Ergebnisse zu überprüfen?
          </ng-template>
        </ngb-panel>
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span>
              <h4>Aufgabe 4</h4>
            </span>
          </ng-template>
          <ng-template ngbPanelContent>
            Was sind moralische Bedenken, die sich ergeben, wenn Schüler*innen oder
            Studierende ChatGPT im Unterricht verwenden?
          </ng-template>
        </ngb-panel>
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span>
              <h4>Aufgabe 5</h4>
            </span>
          </ng-template>
          <ng-template ngbPanelContent>
            Wie können Modelle wie ChatGPT besonders in
            Bereichen wie Nachrichtenmedien und Politik missbraucht werden?
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</div>
