<div class="container pt-3">
    <h1>Impressum</h1>
    <p>Angaben gemäß § 5 TMG</p>
    <p>
        <strong>Anschrift:</strong><br>
        Christian-Albrechts-Universität zu Kiel <br>
        Christian-Albrechts-Platz 4 <br>
        24118 Kiel, Germany <br>
    </p>
    <p>
        <strong>Kontakt:</strong> <br>
        Telefon: +49 (0431) 880-00<br>
        E-Mail: <a href='mailto:mail@uni-kiel.de'>mail@uni-kiel.de</a><br>
    </p>
    <p>
        <strong>Umsatzsteuer-ID</strong> gemäß §27a UStG.: <br>
        DE 811317279<br>
    </p>
    <p>
        <strong>Aufsichtsbehörde:</strong><br>
        Ministerium für Bildung, Wissenschaft und Kultur <br>
        Brunswiker Straße 16-22 <br>
        24105 Kiel <br>
    </p>
    <p>
        <strong>Gesetzliche Vertretung:</strong><br>
        Die Christian-Albrechts-Universität zu Kiel ist eine Körperschaft des Öffentlichen Rechts. <br>
        Sie wird gesetzlich vertreten durch das Präsidium:
    </p>
    <ul>
        <li> </li><!--Platz für Präsident*in-->
        <li> die Vizepräsidentin Prof. Dr. Nele Matz-Lück</li>
        <li> den Vizepräsidenten Prof. Dr. Markus Hundt </li>
        <li> den Vizepräsidenten Prof. Dr.-Ing. Eckhard Quandt </li>
        <li> die Kanzlerin Claudia Ricarda Meyer </li>
    </ul>

    <p>
        <strong>Haftungsausschluss für Links: </strong><br>
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
        können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
        stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
        Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
        Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
        derartige Links umgehend entfernen.
    </p>
</div>