import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css'],
})
export class ConfigurationComponent implements OnInit {
  // in and outputs for this component
  @Input() modelCreated = false;
  @Input() isTraining = false;
  @Input() isTesting = false;
  @Output() configSubmitted = new EventEmitter<UntypedFormGroup>();
  @Output() stopTrain = new EventEmitter();
  @Output() test = new EventEmitter();
  @Output() stopTest = new EventEmitter();

  configurationForm: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {
    // this handles the form for parameters
    this.configurationForm = new UntypedFormGroup({
      iteration: new UntypedFormControl(30, [
        Validators.required,
        Validators.min(1),
      ]),
      gamesInIter: new UntypedFormControl(20, [
        Validators.required,
        Validators.min(1),
      ]),
      steps: new UntypedFormControl(500, [
        Validators.required,
        Validators.min(100),
      ]),
      discount: new UntypedFormControl(0.95, [
        Validators.required,
        Validators.min(0),
        Validators.max(1),
      ]),
      learn: new UntypedFormControl(0.05, [
        Validators.required,
        Validators.min(0),
        Validators.max(1),
      ]),
      render: new UntypedFormControl(false),
    });
    this.configurationForm.statusChanges.subscribe((status) => {
      // // logging for debugging
      // console.log(status)
    });
  }

  // form was submitted
  onSubmit(): void {
    console.log(this.configurationForm);
    if (!this.isTraining) {
      this.configSubmitted.emit(this.configurationForm);
    } else {
      this.stopTrain.emit();
    }
  }

  // user clicked on test
  onTest(): void {
    // console.log('clicked test');
    if (!this.isTesting) {
      // console.log('currently not testing, starting');
      this.test.emit();
      // this.configurationForm.disable();
    } else {
      // console.log('currently testing, stopping');
      this.stopTest.emit();
      // this.configurationForm.enable();
    }
  }
}
