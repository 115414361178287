<div class="container pt-3">
    <div class="row mb-3">
        <div class="col">
            <h3>Notebooks!</h3>
            <p>
                Willkommen zum letzten interaktiven Bereich unserer Website, in dem du die Grundlagen des maschinellen
                Lernens
                erlernen und selbst ausprobieren kannst! Eine der leistungsstärksten und flexibelsten Möglichkeiten, mit
                Daten zu arbeiten und Modelle zu erstellen, ist die Verwendung von Jupyter-Notebooks. Mit unseren
                Browser-basierten Notebooks kannst du direkt auf unserer Website Python Programmcode schreiben und
                ausführen,
                um deine eigenen maschinellen Lernmodelle zu erstellen. Wir haben Beispiele und Übungen vorbereitet, die
                dir helfen, dich mit den verschiedenen Aspekten des maschinellen Lernens vertraut zu machen, und wir
                ermutigen dich, deine eigenen Ideen auszuprobieren und zu experimentieren. Viel Spaß beim Lernen!
            </p>
            <ol>
                <li>Finde das Notebook, mit dem du arbeiten möchtest, und klicke auf das Start-Symbol, um
                    es zu
                    öffnen.</li>
                <li>
                    Sobald das Notebook geöffnet ist, solltest du eine Reihe von Zellen sehen, die Code, Text oder
                    andere
                    Inhalte enthalten. Um die Code-Zellen auszuführen und ihre Ausgabe zu sehen, kannst du entweder auf
                    die
                    Schaltfläche "Run" in der Symbolleiste klicken oder die Tastenkombination "Shift+Enter" verwenden.
                </li>
                <li>
                    Wenn du ein von uns erstelltes Notebook verwenden mächtest, solltest du zuvor einmal alle Zellen
                    ausführen.
                    Dies kannst du unter <i>Ausführen</i> -> <i>Alle ausführen</i> mit einem Tastendruck.
                    Die Sprache kannst du ebenfalls umstellen.
                </li>
            </ol>
            <div class="row">
                <div class="col-md-6">
                    <img src="assets/notebooks/run.png.webp" alt="" class="instruction-imgs">
                </div>
                <div class="col-md-6">
                    <img src="assets/notebooks/lang.png.webp" alt="" class="instruction-imgs">
                </div>
            </div>
        </div>

        <div class="col-md-3 align-self-center">
            <div class="d-flex flex-column justify-content-center align-items-center">
                <a href="https://progly.informatik.uni-kiel.de/ki-labor/jupyterlite-root/lab/index.html" target="_blank"
                    class="btn btn-primary btn-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100" fill="currentColor" class="bi bi-play-fill"
                        viewBox="0 0 16 16">
                        <path
                            d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                    </svg>
                </a>
            </div>
        </div>
    </div>

    <app-notebook-entry
        notebookLink="https://progly.informatik.uni-kiel.de/ki-labor/jupyterlite-root/lab/index.html?path=linear-regression.ipynb"
        notebookTitle="Lineare Regression">
        Bei linearer Regression handelt es sich um ein statistisches Verfahren, womit ein einfaches Modell
        erstellt wird, welches einen Datensatz optimal abbildet.
        Oft wird dieses Verfahren verwendet, um einen Trend in Daten zu erkennen, womit eventuell eine
        Vorhersage für zukünftige Werte gemacht werden kann.
    </app-notebook-entry>

    <app-notebook-entry
        notebookLink="https://progly.informatik.uni-kiel.de/ki-labor/jupyterlite-root/lab/index.html?path=micrograd.ipynb"
        notebookTitle="Micrograd">
        Micrograd ist ein minimales Tool von Andrej Karpathy, mit dem die interne Funktionalität von neuronalen Netzen veranschaulicht werden kann.
        Wir verwenden dieses Tool, um ein kleines neuronales Netz zu erstellen und dieses anhand von kleinen Datensätzen zu trainieren.
        Die Konzepte aus diesem Notebook lassen sich auf beliebig große neuronale Netze ausweiten.
    </app-notebook-entry>

    <div class="row" *ngIf="webRequest">
        <div *ngFor="let description of notebookDesc; index as i" class="py-3">

            <div [innerHtml]="markdown(description.title)"></div>
            <div [innerHtml]="markdown(description.description)"></div>
            <p>Notebook Starten:
                <a target="_blank" rel="noreferrer noopener" class="px-2"
                    href="https://mybinder.org/v2/gh/{{repoLink}}/HEAD?labpath={{description.fileName}}">
                    <img src="assets/notebooks/badge_logo.svg" alt="" srcset=""></a>
            </p>
        </div>
    </div>
</div>