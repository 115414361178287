<input #uploader type="file" style="display: none" accept="image/x-png,image/gif,image/jpeg"
    [disabled]="inputDisabled" />
<figure class="figure">
    <figcaption class="figure-caption">{{description}}</figcaption>
    <img #image class="" [src]="imgSrc" height="256" crossorigin='anonymous' />
</figure>

<div class="form-floating">
    <select #select class="form-select" id="floatingSelect" aria-label="Floating label select example"
        (change)="onSelectChange(image, select, uploader)" [disabled]="inputDisabled">
        <option selected value="" disabled>Bild Auswählen</option>
        <option value="pic" (click)="openCameraModal(select, content)">Bild Aufnehmen</option>
        <option value="file">Bild Hochladen</option>
        <option *ngFor="let x of listOfImages" [value]="x.path">{{x.name}}</option>
    </select>
    <label for="floatingSelect">Works with selects</label>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Kamera</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-gan-transfer-webcam (newImage)="onNewImage($event)"></app-gan-transfer-webcam>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Ok</button>
    </div>
</ng-template>