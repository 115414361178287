import { Component } from '@angular/core';

@Component({
  selector: 'app-classify-help',
  templateUrl: './classify-help.component.html',
  styleUrls: ['./classify-help.component.css']
})
export class ClassifyHelpComponent {

  public page = 1;
  basePath = 'assets/classify-images';

  constructor() { }

}
