export default [
    'https://uploads0.wikiart.org/00103/images/carles-delclaux-is/rostolls-cremats-1988-carles-delclaux-cdan.jpg',
    'https://uploads0.wikiart.org/00108/images/alexandru-phoebus/muncitor-agricultura-1931.jpg',
    'https://uploads0.wikiart.org/00114/images/julia-margaret-cameron/annie-1864.jpg',
    'https://uploads0.wikiart.org/00114/images/julia-margaret-cameron/julia-stephen-born-julia-jackson-1867.jpg',
    'https://uploads0.wikiart.org/00114/images/julia-margaret-cameron/saint-cecilia-after-the-manner-of-raphael-1865.jpg',
    'https://uploads0.wikiart.org/00115/images/otto-van-veen/the-romans-under-claudius-civilis-cerealis-defeated-by-the-treachery-of-a-batavian.jpg',
    'https://uploads0.wikiart.org/00116/images/siah-armajani/shirt-1959.jpg',
    'https://uploads0.wikiart.org/00118/images/anton-raphael-mengs/pleasure-1754.jpg',
    'https://uploads0.wikiart.org/00120/images/agostino-tassi/naufragio-della-flotta-di-enea-1627.jpg',
    'https://uploads0.wikiart.org/00122/images/otto-marseus-van-schrieck/a-forest-floor-still-life-with-mushrooms-a-snake-and-a-butterfly-1657.jpg',
    'https://uploads0.wikiart.org/00122/images/otto-marseus-van-schrieck/plants-frogs-butterflies-and-a-snake-on-a-forest-ground-1670.jpg',
    'https://uploads0.wikiart.org/00124/images/joseph-vernet/89510-rm150891-img.jpg',
    'https://uploads0.wikiart.org/00124/images/joseph-vernet/claude-joseph-vernet-nuit-sce-ne-co-te-me-diterrane-enne-avec-les-pe-cheurs-et-les-bateaux.jpg',
    'https://uploads0.wikiart.org/00125/images/blek-le-rat/blek-le-rat.jpg',
    'https://uploads0.wikiart.org/00127/images/eric-gill/crucifix-1917.jpg',
    'https://uploads0.wikiart.org/00127/images/j-c-leyendecker/arrow-shirt-collar-advertisement-1.jpg',
    'https://uploads0.wikiart.org/00128/images/claude-joseph-vernet/vedi-titolo.jpg',
    'https://uploads0.wikiart.org/00128/images/luke-fildes/the-widower.jpg',
    'https://uploads0.wikiart.org/00129/images/annibale-carracci/butcher-s-shop.jpg',
    'https://uploads0.wikiart.org/00129/images/canaletto/capriccio-a-palladian-design-for-the-rialto-bridge-with-buildings-at-vicenza.jpg',
    'https://uploads0.wikiart.org/00129/images/giorgione/self-portrait.jpg',
    'https://uploads0.wikiart.org/00129/images/jean-auguste-dominique-ingres/the-grande-odalisque.jpg',
    'https://uploads0.wikiart.org/00129/images/michelangelo/the-torment-of-saint-anthony.jpg',
    'https://uploads0.wikiart.org/00132/images/salvator-rosa/odysseus-and-nausicaa-lacma-49-17-4.jpg',
    'https://uploads0.wikiart.org/00132/images/salvator-rosa/salvator-rosa-odysseus-and-nausicaa-wga20052.jpg',
    'https://uploads0.wikiart.org/00134/images/toshusai-sharaku/arashi-ry-z-i-as-ishibe-kinkichi.jpg',
    'https://uploads0.wikiart.org/00134/images/toshusai-sharaku/k-shir-matsumoto-iv-as-sakanaya-gorobee-by-sharaku.jpg',
    'https://uploads0.wikiart.org/00135/images/stanislaw-wyspianski/stanis-aw-wyspia-ski-autoportret-1890.jpg',
    'https://uploads0.wikiart.org/00135/images/stanislaw-wyspianski/stanislaw-wyspianski-portrait-tadeusz-boy-zelenski-1894.jpg',
    'https://uploads0.wikiart.org/00135/images/toshusai-sharaku/sharaku-1794-nakamura-nakaz-ii-as-aramaki-mimishir-kanetora.jpg',
    'https://uploads0.wikiart.org/00135/images/toshusai-sharaku/sharaku-1794-sakata-hangor-iii-as-kosodate-kannonb.jpg',
    'https://uploads0.wikiart.org/00135/images/toshusai-sharaku/sharaku-1794-sakata-hangor-iii-as-the-groom-abumizuri-no-iwaz-in-koriyama-actually-kurisaka-tar.jpg',
    'https://uploads0.wikiart.org/00140/images/charles-garabedian/family-portrait-1964.jpg',
    'https://uploads0.wikiart.org/00141/images/juriaen-van-streeck/f989ob8pws.jpg',
    'https://uploads0.wikiart.org/00142/images/57726d7cedc2cb3880b47b1a/the-cardsharps.jpg',
    'https://uploads0.wikiart.org/00142/images/57726d7cedc2cb3880b47b32/pieter-bruegel-the-elder-naval-battle-in-the-gulf-of-naples-wga03522.jpg',
    'https://uploads0.wikiart.org/00142/images/57726d7eedc2cb3880b47ec0/gericaultwoundedcavalry.jpg',
    'https://uploads0.wikiart.org/00142/images/57726d84edc2cb3880b48c9b/vecellio-di-gregorio-tiziano-autoritratto.jpg',
    'https://uploads0.wikiart.org/00142/images/57726dc4edc2cb3880b4fca0/pinturicchio-soffitto-dei-semidei-1490-roma-palazzo-dei-penitenzieri.jpg',
    'https://uploads0.wikiart.org/00142/images/canaletto/venice-the-grand-canal-from-palazzo-flangini-to-the-church-of-san-marcuola.jpg',
    'https://uploads0.wikiart.org/00142/images/jacob-van-ruisdael/view-of-haarlem-with-bleaching-grounds.jpg',
    'https://uploads0.wikiart.org/00142/images/leonardo-da-vinci/portrait-of-an-unknown-woman-la-belle-ferroniere.jpg',
    'https://uploads0.wikiart.org/00142/images/pieter-aertsen/butcher-s-stall-with-the-flight-into-egypt.jpg',
    'https://uploads0.wikiart.org/00142/images/rogier-van-der-weyden/madonna-and-child.jpg',
    'https://uploads0.wikiart.org/00151/images/antonio-lopez/artists-working.jpg',
    'https://uploads0.wikiart.org/00152/images/leonetto-cappiello/affiche-pneu-baudou.jpg',
    'https://uploads0.wikiart.org/00152/images/leonetto-cappiello/amandines-de-provence-poster-by-leonetto-cappiello-1900.jpg',
    'https://uploads0.wikiart.org/00157/images/oleg-holosiy/1991-23.jpg',
    'https://uploads0.wikiart.org/00157/images/oleg-holosiy/1991-24.jpg',
    'https://uploads0.wikiart.org/00157/images/oleg-holosiy/1991-27.jpg',
    'https://uploads0.wikiart.org/00157/images/oleg-holosiy/1991-28.jpg',
    'https://uploads0.wikiart.org/00158/images/oleg-holosiy/flamy-horse-1990.jpg',
    'https://uploads0.wikiart.org/00166/images/olowe-of-ise/nmafa-d20060036-000005-8.jpg',
    'https://uploads0.wikiart.org/00166/images/olowe-of-ise/nmafa-d20060036-000005-9.jpg',
    'https://uploads0.wikiart.org/00166/images/olowe-of-ise/walu-ad-olowere-tr-african-arts-1-688x8972.jpg',
    'https://uploads0.wikiart.org/00169/images/l-s-lowry/frank-jopling-fletcher-1919.jpg',
    'https://uploads0.wikiart.org/00174/images/lucas-cranach-the-elder/lucas-cranach-d-063-1.jpg',
    'https://uploads0.wikiart.org/00175/images/rosso-fiorentino/rosso-fiorentino-a-young-man-wga20114.jpg',
    'https://uploads0.wikiart.org/00179/images/maximilien-luce/le-chantier.jpg',
    'https://uploads0.wikiart.org/00186/images/pixel-icons/aivazovsky-exchange-of-peterburg.jpg',
    'https://uploads0.wikiart.org/00186/images/pixel-icons/picasso-bust-of-a-woman.jpg',
    'https://uploads0.wikiart.org/00186/images/pixel-icons/rousseau-still-life-with-teapot-and-fruit.jpg',
    'https://uploads0.wikiart.org/00186/images/pixel-icons/van-gogh-still-life-with-blue-enamel-coffeepot-earthenware-and-fruit.jpg',
    'https://uploads0.wikiart.org/00202/images/francesco-del-cossa/francesco-del-cossa-001.jpg',
    'https://uploads0.wikiart.org/00202/images/francesco-del-cossa/francesco-del-cossa-003.jpg',
    'https://uploads0.wikiart.org/00202/images/francesco-del-cossa/francesco-del-cossa-004.jpg',
    'https://uploads0.wikiart.org/00202/images/francesco-del-cossa/francesco-del-cossa-012.jpg',
    'https://uploads0.wikiart.org/00202/images/francesco-del-cossa/palazzo-schifanoia-salone-dei-mesi-04-aprile-f-del-cossa-toro-02-\
    2-1.jpg',
    'https://uploads0.wikiart.org/00202/images/francesco-del-cossa/palazzo-schifanoia-salone-dei-mesi-04-aprile-f-del-cossa-toro-03-3\
    -1.jpg',
    'https://uploads0.wikiart.org/00202/images/francesco-del-cossa/palazzo-schifanoia-salone-dei-mesi-04-aprile-f-del-cossa-trionfo-di\
    -venere-02-coniglio.jpg',
    'https://uploads0.wikiart.org/00203/images/david-lynch/boom.jpg',
    'https://uploads0.wikiart.org/00203/images/david-lynch/i-see-my-love.jpg',
    'https://uploads0.wikiart.org/00204/images/odd-nerdrum/self-portrait-as-polar-exploere.jpg',
    'https://uploads0.wikiart.org/00204/images/odd-nerdrum/self-portrait-with-melting-eyes.jpg',
    'https://uploads0.wikiart.org/00205/images/keith-haring/epub000584-1.jpg',
    'https://uploads0.wikiart.org/00205/images/keith-haring/epub000586-1.jpg',
    'https://uploads0.wikiart.org/00205/images/simon-de-vlieger/shipping-off-the-english-coast-1643.jpg',
    'https://uploads0.wikiart.org/00206/images/giovanni-da-milano/giovanni-da-milano-orazione-nell-orto-1355-60-ca-01.jpg',
    'https://uploads0.wikiart.org/00207/images/giovanni-da-milano/giovanni-da-milano-the-birth-of-the-virgin-wga09405.jpg',
    'https://uploads0.wikiart.org/00207/images/hans-gude/hansgude-frafjellet-1849.jpg',
    'https://uploads0.wikiart.org/00207/images/jeffrey-smart/at-the-garage-1959-1.jpg',
    'https://uploads0.wikiart.org/00207/images/jeffrey-smart/self-portrait-1964-1.jpg',
    'https://uploads0.wikiart.org/00209/images/paul-gauguin/paul-gauguin-delightful-land-te-nave-nave-fenua-google-art-project.jpg',
    'https://uploads0.wikiart.org/00210/images/fayum-portrait/mummy-portrait-getty-center.jpg',
    'https://uploads0.wikiart.org/00210/images/ivan-eyre/study-for-black-woman-drawing-ivan-eyre-1.jpg',
    'https://uploads0.wikiart.org/00210/images/james-turrell/bild-006-1440x972-1.jpg',
    'https://uploads0.wikiart.org/00210/images/jean-fautrier/faut-fusil.jpg',
    'https://uploads0.wikiart.org/images/ad-reinhardt/abstract-painting-red-1952.jpg',
    'https://uploads0.wikiart.org/images/ad-reinhardt/untitled-red-and-gray-1950.jpg',
    'https://uploads0.wikiart.org/images/adolph-menzel/abendgesellschaft-1847(1).jpg',
    'https://uploads0.wikiart.org/images/adolph-menzel/building-site-with-willows-1846(1).jpg',
    'https://uploads0.wikiart.org/images/adolph-menzel/emilie-menzel-asleep-1848.jpg',
    'https://uploads0.wikiart.org/images/aelbert-cuyp/the-maas-at-dordrecht-1650.jpg',
    'https://uploads0.wikiart.org/images/agostino-carracci/alcibiade-and-glycere.jpg',
    'https://uploads0.wikiart.org/images/agostino-carracci/dog.jpg',
    'https://uploads0.wikiart.org/images/agostino-carracci/hercules-and-deianira.jpg',
    'https://uploads0.wikiart.org/images/agostino-carracci/julie-with-an-athlete.jpg',
    'https://uploads0.wikiart.org/images/ahmed-karahisari/calligraphy-exercise-1.jpg',
    'https://uploads0.wikiart.org/images/ahmed-karahisari/tile.jpg',
    'https://uploads0.wikiart.org/images/ahmed-karahisari/unknown-title.jpg',
    'https://uploads0.wikiart.org/images/albert-bierstadt/grizzly-bears.jpg',
    'https://uploads0.wikiart.org/images/albert-dubois-pillet/charlands-sur-la-seine-1885.jpg',
    'https://uploads0.wikiart.org/images/albert-marquet/street-lamp-arcueil-1899.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/coat-of-arms-with-open-man-behind.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/combined-coat-of-arms-of-the-tucher-and-rieter-families-1499.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/courtyard-of-the-former-castle-in-innsbruck-with-clouds-1494.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/forest-glade-with-a-walled-fountain-by-which-two-men-are-sitting-1505.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/house-by-a-pond.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/lillium-martagon-details.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/male-nude-with-a-glass-and-snake-asclepius.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/parrot.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/quarry.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/rhinoceros-1515.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/saint-john-s-church-1489.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/self-portrait-at-13-1484.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/soldiers-under-the-cross.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/the-owl-in-fight-with-other-birds.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/the-southern-hemisphere-of-the-celestial-globe-1515.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/third-knot-1507.jpg',
    'https://uploads0.wikiart.org/images/albrecht-durer/watermill-at-the-monta\u0441a.jpg',
    'https://uploads0.wikiart.org/images/aleksandr-deyneka/work-build-and-don-t-weep-1933.jpg',
    'https://uploads0.wikiart.org/images/alex-colville/woman-at-clothesline-1957.jpg',
    'https://uploads0.wikiart.org/images/alexander-ivanov/head-of-a-man.jpg',
    'https://uploads0.wikiart.org/images/alexander-ivanov/white-horse-study-1820.jpg',
    'https://uploads0.wikiart.org/images/alexey-venetsianov/in-the-fields-spring.jpg',
    'https://uploads0.wikiart.org/images/alexey-venetsianov/threshing-floor-1822.jpg',
    'https://uploads0.wikiart.org/images/alfred-manessier/untitled-1946.jpg',
    'https://uploads0.wikiart.org/images/alphonse-allais/first-communion-of-anaemic-young-girls-in-the-snow.jpg',
    'https://uploads0.wikiart.org/images/andre-derain/the-port-of-collioure-1905.jpg',
    'https://uploads0.wikiart.org/images/andrea-del-sarto/self-portrait.jpg',
    'https://uploads0.wikiart.org/images/andrea-mantegna/captured-statues-and-siege-equipment-1506.jpg',
    'https://uploads0.wikiart.org/images/andrea-mantegna/children-playing-with-masks-1495.jpg',
    'https://uploads0.wikiart.org/images/andrea-mantegna/the-agony-in-the-garden.jpg',
    'https://uploads0.wikiart.org/images/andrea-mantegna/two-studies-for-christ-at-the-column-1459.jpg',
    'https://uploads0.wikiart.org/images/andy-warhol/telephone.jpg',
    'https://uploads0.wikiart.org/images/annibale-carracci/hunting-1588.jpg',
    'https://uploads0.wikiart.org/images/annibale-carracci/the-virgin-appears-to-st-luke-and-catherine-1592.jpg',
    'https://uploads0.wikiart.org/images/antoine-watteau/a-seated-woman.jpg',
    'https://uploads0.wikiart.org/images/antoine-watteau/cupid-disarmed.jpg',
    'https://uploads0.wikiart.org/images/antoine-watteau/embarkation-for-cythere.jpg',
    'https://uploads0.wikiart.org/images/antoine-watteau/love-in-the-french-theather-1716.jpg',
    'https://uploads0.wikiart.org/images/antoine-watteau/sitting-couple.jpg',
    'https://uploads0.wikiart.org/images/antoine-watteau/study-of-a-woman-s-head.jpg',
    'https://uploads0.wikiart.org/images/antoine-watteau/the-dance-1720.jpg',
    'https://uploads0.wikiart.org/images/antoine-watteau/the-lesson-of-love-1716.jpg',
    'https://uploads0.wikiart.org/images/artemisia-gentileschi/an-allegory-of-peace-and-the-arts-under-the-english-crown-1638.jpg',
    'https://uploads0.wikiart.org/images/bada-shanren/landscape-dong-yuan-and-juran-style.jpg',
    'https://uploads0.wikiart.org/images/barnett-newman/untitled-2-1948.jpg',
    'https://uploads0.wikiart.org/images/barnett-newman/untitled-3-1949.jpg',
    'https://uploads0.wikiart.org/images/barnett-newman/untitled-i-1950.jpg',
    'https://uploads0.wikiart.org/images/benjamin-west/fidelia-and-speranza-1776.jpg',
    'https://uploads0.wikiart.org/images/benozzo-gozzoli/angels-worshipping-detail-1461-1.jpg',
    'https://uploads0.wikiart.org/images/benvenuto-tisi/ascension-of-christ-1520.jpg',
    'https://uploads0.wikiart.org/images/benvenuto-tisi/madonna-con-bambino-1510.jpg',
    'https://uploads0.wikiart.org/images/bernardo-marques/antonio-ferro-the-age-of-the-jazz-band-cover-1924.jpg',
    'https://uploads0.wikiart.org/images/bernardo-marques/untitled-1920.jpg',
    'https://uploads0.wikiart.org/images/bill-woodrow/cerium-from-the-periodic-table-1994.jpg',
    'https://uploads0.wikiart.org/images/boris-kustodiev/poster-leningrad-department-of-state-publishing-lengiz-1925.jpg',
    'https://uploads0.wikiart.org/images/camille-corot/self-portrait-sitting-next-to-an-easel-1825.jpg',
    'https://uploads0.wikiart.org/images/camille-pissarro/paysanne-couchant-1882.jpg',
    'https://uploads0.wikiart.org/images/canaletto/campo-santi-apostoli.jpg',
    'https://uploads0.wikiart.org/images/canaletto/grand-canal-looking-from-palazzo-balbi.jpg',
    'https://uploads0.wikiart.org/images/canaletto/grand-canal.jpg',
    'https://uploads0.wikiart.org/images/canaletto/riva-degli-schiavoni-looking-east-1730.jpg',
    'https://uploads0.wikiart.org/images/canaletto/river-landscape-with-a-column.jpg',
    'https://uploads0.wikiart.org/images/canaletto/venice-the-grand-canal-from-palazzo-flangini-to-the-church-of-san-marcuola.jpg',
    'https://uploads0.wikiart.org/images/canaletto/view-of-the-ducal-palace-1730.jpg',
    'https://uploads0.wikiart.org/images/candido-portinari/favelas-1930.jpg',
    'https://uploads0.wikiart.org/images/caravaggio/bacchus(1).jpg',
    'https://uploads0.wikiart.org/images/caravaggio/saint-francis-of-assisi-in-ecstasy(1).jpg',
    'https://uploads0.wikiart.org/images/carmelo-arden-quin/mad-ii-1945.jpg',
    'https://uploads0.wikiart.org/images/cassandre/pivolo-1924.jpg',
    'https://uploads0.wikiart.org/images/charles-lapicque/rencontre-dans-la-campagne-1944.jpg',
    'https://uploads0.wikiart.org/images/charles-lapicque/untitled-1945.jpg',
    'https://uploads0.wikiart.org/images/charles-martin-powell/sailboats-and-fishing-boats-on-a-choppy-lake.jpg',
    'https://uploads0.wikiart.org/images/charles-martin-powell/sea-piece-with-war-vessels.jpg',
    'https://uploads0.wikiart.org/images/charles-martin-powell/seascape.jpg',
    'https://uploads0.wikiart.org/images/chen-hong/the-eight-noble-officials-735.jpg',
    'https://uploads0.wikiart.org/images/christina-robertson/olga-ivanovna-orlova-davydova-baryatinsky-1841.jpg',
    'https://uploads0.wikiart.org/images/christina-robertson/portrait-of-grand-duchess-olga-nikolaevna-1841.jpg',
    'https://uploads0.wikiart.org/images/christina-robertson/portrait-of-grand-duchesses-olga-nikolaevna-and-alexandra-nikolaevna-1840.jpg',
    'https://uploads0.wikiart.org/images/clara-peeters/still-life-with-nuts-candy-and-flowers-1611.jpg',
    'https://uploads0.wikiart.org/images/claude-lorrain/entrance-to-la-rochelle-harbour-claude-lorrain-1631.jpg',
    'https://uploads0.wikiart.org/images/claude-lorrain/seaport-with-a-big-tower.jpg',
    'https://uploads0.wikiart.org/images/claude-lorrain/the-embarkation-of-the-queen-of-sheba-1648.jpg',
    'https://uploads0.wikiart.org/images/currier-and-ives/campaign-poster-for-1860-u-s-presidential-candidate-john-bell-and-his-running-mate-edward-1860.jpg',
    'https://uploads0.wikiart.org/images/currier-and-ives/come-take-a-drink-1868.jpg',
    'https://uploads0.wikiart.org/images/cy-twombly/untitled-1.jpg',
    'https://uploads0.wikiart.org/images/cy-twombly/untitled-2.jpg',
    'https://uploads0.wikiart.org/images/david-burliuk/abstraction-1910.jpg',
    'https://uploads0.wikiart.org/images/david-cox/the-cross-roads-1850.jpg',
    'https://uploads0.wikiart.org/images/david-cox/the-night-train-1849.jpg',
    'https://uploads0.wikiart.org/images/david-cox/ulverston-sands-1835.jpg',
    'https://uploads0.wikiart.org/images/david-park/reclining-nude-1960.jpg',
    'https://uploads0.wikiart.org/images/david-park/torso-1959.jpg',
    'https://uploads0.wikiart.org/images/diego-rivera/bather-of-tehuantepec-1923.jpg',
    'https://uploads0.wikiart.org/images/diego-rivera/edsel-b-ford-1932.jpg',
    'https://uploads0.wikiart.org/images/edgar-degas/alexander-and-bucephalus-detail-1861.jpg',
    'https://uploads0.wikiart.org/images/edgar-degas/at-the-races-the-start-1862.jpg',
    'https://uploads0.wikiart.org/images/edgar-degas/dante-and-virgil-at-the-entrance-to-hell-1858.jpg',
    'https://uploads0.wikiart.org/images/edgar-degas/portrait-of-an-italian-1856.jpg',
    'https://uploads0.wikiart.org/images/edgar-degas/self-portrait-1855.jpg',
    'https://uploads0.wikiart.org/images/edouard-manet/a-woman-pouring-water-study-of-suzanne-leenhoff-1858.jpg',
    'https://uploads0.wikiart.org/images/edvard-munch/by-the-deathbed-fever-1893.jpg',
    'https://uploads0.wikiart.org/images/edvard-munch/death-in-the-sickroom-1893.jpg',
    'https://uploads0.wikiart.org/images/edvard-munch/girl-combing-her-hair-1892.jpg',
    'https://uploads0.wikiart.org/images/edvard-munch/the-scream-1893-1.jpg',
    'https://uploads0.wikiart.org/images/edward-corbett/untitled-3-1950.jpg',
    'https://uploads0.wikiart.org/images/edward-hicks/view-from-the-tempest-1825.jpg',
    'https://uploads0.wikiart.org/images/edward-hopper/sun-on-prospect-street-gloucester-massachusetts-1934.jpg',
    'https://uploads0.wikiart.org/images/edwin-henry-landseer/ptarmigan.jpg',
    'https://uploads0.wikiart.org/images/ellsworth-kelly/blue-from-the-series-line-form-color-1951.jpg',
    'https://uploads0.wikiart.org/images/ellsworth-kelly/vertical-line-from-the-series-line-form-color-1951.jpg',
    'https://uploads0.wikiart.org/images/emperor-huizong/plum-and-birds.jpg',
    'https://uploads0.wikiart.org/images/ende/adoraci-n-de-la-estatua-de-oro-y-los-tres-j-venes-en-el-horno.jpg',
    'https://uploads0.wikiart.org/images/ende/prologue-on-the-church-the-map-of-the-world.jpg',
    'https://uploads0.wikiart.org/images/ende/visi-n-del-profeta-daniel.jpg',
    'https://uploads0.wikiart.org/images/esaias-van-de-velde/title-page-with-tools-for-cultivating-the-land.jpg',
    'https://uploads0.wikiart.org/images/eugene-delacroix/lion-devouring-a-rabbit-1856(1).jpg',
    'https://uploads0.wikiart.org/images/eugene-delacroix/roaring-lion-s-head(1).jpg',
    'https://uploads0.wikiart.org/images/eugene-delacroix/tiger-1830(1).jpg',
    'https://uploads0.wikiart.org/images/facundus/la-femme-sur-la-b-te-apoc-xvii.jpg',
    'https://uploads0.wikiart.org/images/fede-galizia/still-life-1610-1.jpg',
    'https://uploads0.wikiart.org/images/felix-vallotton/self-portrait-with-the-dressing-gown-1914.jpg',
    'https://uploads0.wikiart.org/images/felix-vallotton/still-life-with-red-peppers-on-a-white-lacquered-table-1915.jpg',
    'https://uploads0.wikiart.org/images/felix-vallotton/the-rape-of-europa-1908.jpg',
    'https://uploads0.wikiart.org/images/felix-vallotton/woman-aiu-being-capped-bath-1897.jpg',
    'https://uploads0.wikiart.org/images/felix-vallotton/woman-with-the-jug-1906.jpg',
    'https://uploads0.wikiart.org/images/fernand-leger/portrait-of-henry-viel-a-friend-of-painter-fernand-leger-1903.jpg',
    'https://uploads0.wikiart.org/images/fernand-leger/portrait-of-henry-viel-a-friend-of-painter-fernand-leger-1904.jpg',
    'https://uploads0.wikiart.org/images/filippo-brunelleschi/sketches-of-the-machines(1).jpg',
    'https://uploads0.wikiart.org/images/francis-picabia/ballerina-on-an-ocean-liner-1913.jpg',
    'https://uploads0.wikiart.org/images/francisco-bayeu-y-subias/the-eternal-father-1791.jpg',
    'https://uploads0.wikiart.org/images/francisco-goya/birth-of-the-virgin-1772.jpg',
    'https://uploads0.wikiart.org/images/francisco-goya/bravissimo-1799.jpg',
    'https://uploads0.wikiart.org/images/francisco-goya/correction-1799.jpg',
    'https://uploads0.wikiart.org/images/francisco-goya/it-is-time-1799.jpg',
    'https://uploads0.wikiart.org/images/francisco-goya/lads-getting-on-with-the-job-1799.jpg',
    'https://uploads0.wikiart.org/images/francisco-goya/the-adoration-of-the-name-of-the-lord-1772.jpg',
    'https://uploads0.wikiart.org/images/frans-snyders/still-life-with-crab-poultry-and-fruit-1620.jpg',
    'https://uploads0.wikiart.org/images/franz-erhard-walther/kurz-vor-der-d-mmerung-1967.jpg',
    'https://uploads0.wikiart.org/images/friedrich-vordemberge-gildewart/unknown-title(1).jpg',
    'https://uploads0.wikiart.org/images/fritz-rehm/otto-perutz-lithographic-advertising-card-1898.jpg',
    'https://uploads0.wikiart.org/images/futura-2000/wall-1982.jpg',
    'https://uploads0.wikiart.org/images/gabriel-von-max/still-life-girl-at-a-spinet-1871.jpg',
    'https://uploads0.wikiart.org/images/george-frederick-watts/sic-transit-1892.jpg',
    'https://uploads0.wikiart.org/images/george-stubbs/cheetah-with-two-indian-servants-and-a-deer.jpg',
    'https://uploads0.wikiart.org/images/george-stubbs/lady-reading-in-a-wooded-park-1770.jpg',
    'https://uploads0.wikiart.org/images/georges-seurat/bathers-at-asni-res-1884.jpg',
    'https://uploads0.wikiart.org/images/georges-seurat/horse-cart-1884.jpg',
    'https://uploads0.wikiart.org/images/georges-seurat/model-from-the-back-1886.jpg',
    'https://uploads0.wikiart.org/images/georges-seurat/portrait-of-edmond-fran\u00e7ois-aman-jean-1883.jpg',
    'https://uploads0.wikiart.org/images/georges-seurat/sketch-with-many-figures-for-sunday-afternoon-on-grande-jatte-1884(1).jpg',
    'https://uploads0.wikiart.org/images/georges-seurat/the-artist-s-mother-1883.jpg',
    'https://uploads0.wikiart.org/images/georges-seurat/the-away-samson-in-grandcamp-1885.jpg',
    'https://uploads0.wikiart.org/images/georges-seurat/the-couple-1884.jpg',
    'https://uploads0.wikiart.org/images/georges-seurat/the-forest-at-pontaubert.jpg',
    'https://uploads0.wikiart.org/images/georges-seurat/view-of-fort-samson-1885.jpg',
    'https://uploads0.wikiart.org/images/georges-vantongerloo/curving-function-greenish-brown-1938.jpg',
    'https://uploads0.wikiart.org/images/giacomo-balla/iridescent-interpenetration-no-4-study-of-light-1912.jpg',
    'https://uploads0.wikiart.org/images/giandomenico-facchina/arch-in-the-dining-hall-room-of-the-coll-ge-sainte-barbe-paris.jpg',
    'https://uploads0.wikiart.org/images/giorgio-barbarelli-da-castelfranco/nymphs-and-children-in-a-landscape-with-shepherds.jpg',
    'https://uploads0.wikiart.org/images/giotto/faith.jpg',
    'https://uploads0.wikiart.org/images/giotto/injustice-1306.jpg',
    'https://uploads0.wikiart.org/images/giovanni-battista-piranesi/a-view-of-the-progress-of-the-water-castle-julia.jpg',
    'https://uploads0.wikiart.org/images/giovanni-battista-piranesi/ancient-altar-with-other-ruins.jpg',
    'https://uploads0.wikiart.org/images/giovanni-battista-piranesi/ancient-mausoleum-erected-to-the-ashes-of-a-roman-emperor.jpg',
    'https://uploads0.wikiart.org/images/giovanni-battista-piranesi/ancient-temple-commonly-known-as-the-health-on-the-way-at-albano-five-miles-distant-from-rome.jpg',
    'https://uploads0.wikiart.org/images/giovanni-battista-piranesi/another-view-of-the-same-scrap.jpg',
    'https://uploads0.wikiart.org/images/giovanni-battista-piranesi/arch-of-augustus-manufactured-by-rimini.jpg',
    'https://uploads0.wikiart.org/images/giovanni-battista-piranesi/arch-of-titus-in-rome.jpg',
    'https://uploads0.wikiart.org/images/giovanni-battista-piranesi/egyptian-obelisk-in-the-plaza-of-st-john-lateran.jpg',
    'https://uploads0.wikiart.org/images/giovanni-battista-piranesi/porches-pulled-around-a-hole-of-the-royal-palace.jpg',
    'https://uploads0.wikiart.org/images/giovanni-battista-piranesi/room-use-of-the-ancient-romans-with-columns-and-niches-adorned-with-statues.jpg',
    'https://uploads0.wikiart.org/images/giovanni-battista-piranesi/the-roman-antiquities-t-1-plate-ii-map-of-ancient-rome-and-forma-urbis-1756.jpg',
    'https://uploads0.wikiart.org/images/giovanni-battista-piranesi/the-roman-antiquities-t-1-plate-iv-map-of-ancient-rome-and-forma-urbis-1756.jpg',
    'https://uploads0.wikiart.org/images/giovanni-battista-piranesi/the-roman-antiquities-t-1-plate-xii-aqua-alsietina-1756-1.jpg',
    'https://uploads0.wikiart.org/images/giovanni-battista-piranesi/the-roman-antiquities-t-1-plate-xv-pantheon-1756.jpg',
    'https://uploads0.wikiart.org/images/giovanni-bellini/madonna-and-child-1490.jpg',
    'https://uploads0.wikiart.org/images/giovanni-paolo-panini/a-capriccio-of-classical-ruins-with-diogenes-throwing-away-his-cup-1729.jpg',
    'https://uploads0.wikiart.org/images/giovanni-paolo-panini/a-capriccio-of-the-roman-forum-1741.jpg',
    'https://uploads0.wikiart.org/images/giovanni-paolo-panini/interior-of-a-picture-gallery-with-the-collection-of-cardinal-silvio-valenti-gonzaga-1740.jpg',
    'https://uploads0.wikiart.org/images/giovanni-paolo-panini/interior-of-st-peter-s-in-rome-1750.jpg',
    'https://uploads0.wikiart.org/images/giovanni-paolo-panini/musical-f-te-1747.jpg',
    'https://uploads0.wikiart.org/images/giovanni-paolo-panini/roman-capriccio-the-pantheon-and-other-monuments-1735.jpg',
    'https://uploads0.wikiart.org/images/guan-daosheng/bamboo-and-stone.jpg',
    'https://uploads0.wikiart.org/images/guanxiu/the-1st-pindolabharadraja-arhat.jpg',
    'https://uploads0.wikiart.org/images/guanxiu/the-4th-panthaka-arhat.jpg',
    'https://uploads0.wikiart.org/images/guo-xi/trees.jpg',
    'https://uploads0.wikiart.org/images/gustave-singier/estacade-baigneuse-1956.jpg',
    'https://uploads0.wikiart.org/images/hakuin-ekaku/true-mountain-covered-with-cloud-shin-un-zan.jpg',
    'https://uploads0.wikiart.org/images/hans-baldung/these-stained-glass-windows-from-the-eastern-side-of-the-blumeneck-family-chapel.jpg',
    'https://uploads0.wikiart.org/images/hans-baldung/these-stained-glass-windows-from-the-western-side-of-the-blumeneck-family-chapel.jpg',
    'https://uploads0.wikiart.org/images/hans-hinterreiter/me-25-b-1935.jpg',
    'https://uploads0.wikiart.org/images/hans-holbein-the-younger/title-page-in-the-form-of-a-renaissance-niche-1516.jpg',
    'https://uploads0.wikiart.org/images/hans-memling/st-john-and-veronica-diptych-reverse-of-the-left-wing.jpg',
    'https://uploads0.wikiart.org/images/hendrick-cornelisz-vroom/the-return-of-prince-charles-from-spain-5-october-1623-1624.jpg',
    'https://uploads0.wikiart.org/images/henri-de-toulouse-lautrec/confetti-1894.jpg',
];
