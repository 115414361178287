export default {
    inputs: [
        { path: 'stata', name: 'Modernes Gebäuse' },
        { path: 'diana', name: 'Prinzessin Diana' },
        { path: 'golden_gate', name: 'Golden Gate Bridge' },
        { path: 'beach', name: 'Strand' },
        { path: 'chicago', name: 'Chicago' },
        { path: 'statue_of_liberty', name: 'Freiheitsstatue' },
        { path: 'nyc_skyline', name: 'New York City' },
    ],
    styles: [
        { path: 'random', name: 'Random Bild von wikiart.org' },
        { path: 'udnie', name: 'Udnie' },
        { path: 'stripes', name: 'Streifen' },
        { path: 'bricks', name: 'Steine' },
        { path: 'clouds', name: 'Wolken' },
        { path: 'towers', name: 'Türme' },
        { path: 'sketch', name: 'Zeichnung' },
        { path: 'seaport', name: 'Hafen' },
        { path: 'red_circles', name: 'Rote Kreise' },
        { path: 'zigzag', name: 'Zickzack' },
        { path: 'starry_night', name: 'Starry Night' },
        { path: 'wave', name: 'Die Welle' },
    ]
};
